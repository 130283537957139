import React, { Component } from 'react';
import { css } from 'emotion';
import EmailConfirmationModal from './email-confirmation-modal';
import error from '../../assets//error-icon.svg';
class InviteNewUserModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowDialog: false,
      email: '',
      emailValid: true
    };
    this.node = null;
  }
  componentDidMount() {
    this.nameInput.focus();
  }

  componentWillMount() {
    document.addEventListener('mousedown', this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
  }

  handleClickOutside = () => {
    this.props.toggeleInviteNewUserModal();
  };

  handleClick = e => {
    if (this.node !== null && this.node.contains(e.target)) {
      return;
    }
    this.handleClickOutside();
  };

  handleCancleBtn = () => {
    this.props.toggeleInviteNewUserModal();
  };

  toggleSendInviteModal = () => {
    this.setState({
      isShowDialog: !this.state.isShowDialog
    });
  };
  handleSendInviteBtn = () => {
    this.sendInvite();
  };
  sendInvite = () => {
    const errorMessage = this.getErrorMessage();
    if (errorMessage === null) {
      var obj = {};
      obj['email'] = this.state.email;
      this.toggleSendInviteModal();
      this.props.inviteNewUser(obj);
    }
  };
  showEmailConfirmationModal = () => {
    let title = 'Email Sent';
    let message = 'An invitaion mail has been successfully sent to ';
    return (
      <EmailConfirmationModal
        title={title}
        message={message}
        toggleSendInviteModal={this.toggleSendInviteModal}
        email={this.state.email}
        handleCloseBtn={this.handleCloseBtn}
      />
    );
  };
  getErrorMessage = () => {
    const { emailValid } = this.state;
    if (!emailValid) {
      return 'Please enter a valid email address';
    }
    return null;
  };
  renderRegisterError(message = 'Please enter all the fields.') {
    const iconStyle = css({
      marginTop: 'auto',
      marginRight: '10px',
      marginBottom: 'auto'
    });
    const textStyle = css({
      marginTop: 'auto',
      marginBottom: 'auto',
      display: 'flex',
      textAlign: 'left',
      fontFamily: 'Roboto',
      color: 'rgb(1, 82, 204)',
      fontSize: '14px',
      fontWeight: '600',
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: '1.5',
      letterSpacing: 'normal'
    });
    const container = css({
      display: 'flex',
      marginLeft: '16px'
    });
    if (message === null) return null;
    return (
      <div className={container}>
        <img src={error} className={iconStyle} alt="" />
        <div className={textStyle}>{message}</div>
      </div>
    );
  }

  validateEmail = email => {
    // eslint-disable-next-line
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };
  handleEmail = event => {
    const email = event.target.value;
    const emailValid = this.validateEmail(email);
    this.setState({ email, emailValid, validInput: true });
  };

  render() {
    return (
      <div className={modalClass}>
        <div className={modalMain} ref={node => (this.node = node)}>
          <div className={container}>
            <span className={title}>{this.props.title}</span>
          </div>
          <div className={container}>
            <span className={modalSubTitle}>{this.props.subtitle}</span>
          </div>
          {this.renderRegisterError(this.getErrorMessage())}
          <div className={inputContainer}>
            <input
              ref={input => {
                this.nameInput = input;
              }}
              className={emailbox}
              type="text"
              name="email"
              placeholder="Email Address"
              onChange={this.handleEmail}
            />
          </div>
          <div className={msgcontainer}>
            <span className={messageStyle}>{this.props.message}</span>
          </div>
          <div className={btn}>
            <div className={cancelBtn} onClick={() => this.handleCancleBtn()}>
              CANCEL
            </div>
            <div className={sendInviteBtn} onClick={this.handleSendInviteBtn}>
              SEND INVITE
            </div>
          </div>
        </div>
        {this.state.isShowDialog ? this.showEmailConfirmationModal() : null}
      </div>
    );
  }
}
const modalClass = css({
  position: 'absolute',
  zIndex: 1,
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',
  background: 'rgba(0, 0, 0, 0.6)'
});
const modalMain = css({
  position: 'absolute',
  background: 'white',
  width: '364px',
  minHeight: '296px',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%,-50%)',
  borderRadius: '4px',
  boxShadow: '0 10px 10px 0 rgba(0, 0, 0, 0.18)'
});

const container = css({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  height: '48px'
});

const msgcontainer = css({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  height: '54px'
});
const title = css({
  paddingLeft: '16px',
  fontFamily: 'Open Sans',
  fontSize: '16px',
  fontWeight: 700,
  fontStyle: 'normal',
  fontStretch: 'normal',
  lineHeight: 1.5,
  letterSpacing: 'normal',
  color: '#112138'
});

const inputContainer = css({
  display: 'flex',
  alignItems: 'center',
  padding: '16px 16px 12px 16px'
});

const emailbox = css({
  width: '332px',
  height: '56px',
  paddingLeft: '16px',
  fontFamily: 'Open Sans',
  fontWeight: 600,
  fontSize: '14px',
  lineHeight: 1.5,
  letterSpacing: '0.5px',
  color: '#112138',
  outline: 'none',
  border: 'solid 1px #dfe1e5',
  '::-webkit-input-placeholder': {
    color: '#c1c7d0'
  },
  '&:hover': { border: 'solid 1.5px #0151cb' }
});

const modalSubTitle = css({
  width: '332px',
  paddingLeft: '16px',
  fontFamily: 'Open Sans',
  fontSize: '14px',
  fontWeight: 'normal',
  fontStyle: 'normal',
  fontStretch: 'normal',
  lineHeight: 1.5,
  letterSpacing: 'normal',
  color: '#5e6c84'
});

const messageStyle = css({
  width: '332px',
  paddingLeft: '16px',
  fontFamily: 'Open Sans',
  fontSize: '12px',
  fontWeight: 'normal',
  fontStyle: 'normal',
  fontStretch: 'normal',
  lineHeight: 1.5,
  letterSpacing: 'normal',
  color: '#5e6c84'
});

const btn = css({
  display: 'flex',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  width: '364px',
  height: '56px',
  borderRadius: '2px',
  backgroundColor: '#fafbfc'
});

const cancelBtn = css({
  width: '50%',
  padding: '16px',
  border: 'solid 1px #ebecf0',
  fontFamily: 'Open Sans',
  fontSize: '14px',
  fontWeight: '700',
  fontStyle: 'normal',
  fontStretch: 'normal',
  lineHeight: 1.5,
  letterSpacing: '1px',
  textAlign: 'center',
  color: '#c1c7d0',
  cursor: 'pointer'
});

const sendInviteBtn = css({
  border: 'solid 1px #ebecf0',
  width: '50%',
  padding: '16px',
  fontFamily: 'Open Sans',
  fontSize: '14px',
  fontWeight: '700',
  fontStyle: 'normal',
  fontStretch: 'normal',
  lineHeight: 1.5,
  letterSpacing: '1px',
  textAlign: 'center',
  color: '#0151cb',
  cursor: 'pointer'
});
export default InviteNewUserModal;
