import React, { Component } from 'react'
import { css } from 'emotion';
import Option from '../option/option';
import './styles.css';


export default class AddApisModal extends Component {
    constructor(props) {
      super(props);
      this.state = {
        tempApis: [] // this variable is going to hold temporary data so
        //that upon clicking on cancel button we will not have any impact on old data
      };
      this.node = null;
      this.onOptionSelected = this.onOptionSelected.bind(this);
    }
    apiList = []; //this variable will hold group ids of groups.
  
    componentDidMount() {
      if(this.apiList !== undefined){
        this.setState({ tempApis: this.apiList.slice(0) });
      }
    }
  
    componentWillMount() {
      document.addEventListener('mousedown', this.handleClick, false);
    }
  
    componentWillUnmount() {
      document.removeEventListener('mousedown', this.handleClick, false);
    }

    handleClickOutside = () => {
      this.props.toggleAddApisModal();
    };
  
    handleClick = e => {
      if (this.node !== null && this.node.contains(e.target)) {
        return;
      }
      this.handleClickOutside();
    };
    onOptionSelected(id, checked) {
      let clickedApis = this.props.apisData.data[id];
      let apisCopy;
      if (checked) {
        apisCopy = this.state.tempApis.slice(0);
        apisCopy.push(clickedApis);
      } else {
        apisCopy = this.state.tempApis.filter(value => !(value.method.includes(clickedApis.method) 
        && value.op.includes(clickedApis.op)));
      }
      this.setState({
        tempApis: apisCopy
      });
    }
    renderSelectedApis = () => {
      return this.state.tempApis.map((obj, index) => {
        return (
          <div className={distCell} key={index}>
            <div className={apiItem}> {obj.method} </div>
          </div>
        );
      });
    };
  
    renderApis = () => {
      return this.props.apisData.data.map((apiObj, index) => {
        return (
          <Option
            Name={apiObj.method + ' (' + apiObj.op + ')'}
            isSelected={this.isApiSelected(apiObj)}
            key={index}
            onSelect={this.onOptionSelected}
            keyId={index}
          />
        );
      });
    };
    isApiSelected = (apiObj ) => {
      if(this.state.tempApis.length){
        for(let i = 0 ; i < this.state.tempApis.length; i++){
          if (this.state.tempApis[i].method.includes(apiObj.method) && this.state.tempApis[i].op.includes(apiObj.op)){
            return true
          }
        }
      }
      return false
    }
    handleAddApis = () => {
      this.props.updateGroupApiData(
        this.state.tempApis,
        this.props.editRowIndex
      );
      this.props.toggleAddApisModal();
    };
  
    handleCancleBtn = () => {
      this.props.toggleAddApisModal();
    };
  
    render() {
      this.apiList = this.props.groupsData[this.props.editRowIndex].apis.map((obj)=>{
        return obj
      });
      if (this.props.apisData === null) {
        return null;
      }
      return (
        <div className={modalClass}>
          <section className={modalMain} ref={node => (this.node = node)}>
            <div className={container}>
              <span className={title}>Add Apis</span>
            </div>
  
            <div className={addDistsItemStyle  + ' thin-horiz-scrollbar'}>
              {this.renderSelectedApis(this.props)}
            </div>
            <div className={container}>
              <span className={title}>All APIS</span>
            </div>
            <div className={distData + ' thin-vert-scrollbar'}>
              {this.renderApis(this.props)}
            </div>
            <div className={btn}>
              <div className={cancleBtn} onClick={() => this.handleCancleBtn()}>
                CANCEL
              </div>
              <div
                className={addDistsBtn}
                onClick={() => this.handleAddApis()}
              >
                ADD APIS
              </div>
            </div>
          </section>
        </div>
      );
    }
  }
  
  const modalClass = css({
    position: 'absolute',
    zIndex: 1,
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, 0.6)'
  });
  
  const modalMain = css({
    position: 'fixed',
    background: 'white',
    width: '364px',
    minHeight: '500px',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    borderRadius: '4px',
    boxShadow: '0 10px 10px 0 rgba(0, 0, 0, 0.18)'
  });
  
  const container = css({
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '48px'
  });
  const title = css({
    paddingLeft: '16px',
    fontFamily: 'Open Sans',
    fontSize: '16px',
    fontWeight: 600,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    color: '#112138'
  });
  const addDistsItemStyle = css({
    margin: 'auto',
    display: 'flex',
    padding: '5px',
    alignItems: 'center',
    flexDirection: 'row',
    width: '336px',
    height: '48px',
    borderRadius: '2px',
    border: 'solid 1px #dfe1e5',
    backgroundColor: '#ffffff',
    overflowY: 'scroll'
  });
  
  const btn = css({
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    width: '364px',
    height: '56px',
    borderRadius: '2px',
    backgroundColor: '#fafbfc'
  });
  
  const cancleBtn = css({
    width: '50%',
    padding: '16px',
    border: 'solid 1px #ebecf0',
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: '700',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.5,
    letterSpacing: '1px',
    textAlign: 'center',
    color: '#c1c7d0',
    cursor: 'pointer'
  });
  
  const addDistsBtn = css({
    border: 'solid 1px #ebecf0',
    width: '50%',
    padding: '16px',
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: '700',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.5,
    letterSpacing: '1px',
    textAlign: 'center',
    color: '#0151cb',
    cursor: 'pointer'
  });
  
  const distData = css({
    width: '100%',
    height: '300px',
    overflowY: 'scroll'
  });
  
  const distCell = css({
    borderRadius: '2px',
    border: 'solid 1px #ebecf0',
    backgroundColor: '#ffffff',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.5',
    letterSpacing: 'normal',
    color: '#6c798f',
    textAlign: 'left',
    minWidth: '84px',
    minHeight: '32px',
    alignItems: 'center',
    marginRight: '8px',
    // wordBreak: "break-word",
    // whiteSpace: "nowrap",
    // overflow: "hidden",
    // textOverflow: "ellipsis"
  });

  const apiItem = css({
    padding: "5px",
    wordBreak: "break-word",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  })