import { REHYDRATE } from 'redux-persist/constants';

export const PAGINATION_DATA_RECIEVED = 'PAGINATION_DATA_RECIEVED';
const CURRENT_PAGE = 1;
const DEFAULT_LIMIT = 10

const initialState = {
  data: {},
  currentPage: CURRENT_PAGE,
  showingStart: CURRENT_PAGE,
  showingEnd: CURRENT_PAGE,
  maxPages: CURRENT_PAGE,
  columnInSort: null,
  limit: DEFAULT_LIMIT,
  refresh: false,
  computedLimit: DEFAULT_LIMIT // Limit computed by calculating available height of table
};

const TYPES = {
  SELECT_ALL: 'SELECT_ALL',
  SELECT: 'SELECT',
  SEARCH_STRING: 'SEARCH_STRING',
  RESET: 'RESET',
  PAGINATE_RIGHT: 'PAGINATE_RIGHT',
  PAGINATE_LEFT: 'PAGINATE_LEFT',
  CHANGE_LIMIT: 'CHANGE_LIMIT',
  REFRESH: 'REFRESH'
};

export default (state = initialState, action) => {
  switch (action.type) {
    case REHYDRATE:
      const newState = { ...initialState };
      return newState;
    case TYPES.PAGINATE_RIGHT:
      return paginateRight(state, action.payload.totalRecords);
    case TYPES.PAGINATE_LEFT:
      return paginateLeft(state, action.payload.totalRecords);
    case TYPES.CHANGE_LIMIT:
      const totalRecords = action.payload.totalRecords;
      const limit = action.payload.limit;
      const shouldRefresh = action.payload.shouldRefresh || state.refresh;
      const shouldChangeComputedLimit = action.payload.changeComputedLimit;
      const changedLimits = getLimits(
        totalRecords,
        state.currentPage,
        limit
      );

      const END = changedLimits.END;
      const START = changedLimits.START;

      return {
        ...state,
        limit: changedLimits.LIMIT,
        showingStart: START,
        showingEnd: END,
        maxPages: changedLimits.MAXPAGES,
        refresh: shouldRefresh,
        computedLimit: shouldChangeComputedLimit ? limit : state.computedLimit
      };
    case PAGINATION_DATA_RECIEVED: {
      const isSearchResult = action.payload.isSearchResult;
      const currentPage = isSearchResult ? 1 : state.currentPage;
      const defaultLimits = getLimits(
        action.payload.totalRecords,
        currentPage,
        state.computedLimit
      );

      const END = defaultLimits.END;
      const START = defaultLimits.START;

      return {
        ...state,
        data: action.payload.data,
        limit: defaultLimits.LIMIT,
        showingStart: START,
        showingEnd: END,
        maxPages: defaultLimits.MAXPAGES
      };
    }
    case TYPES.REFRESH:
      return {
        ...state,
        refresh: action.payload.refresh
      };
    case TYPES.RESET:
      return {
        ...initialState,
        limit: state.limit
      };
    default:
      return state;
  }
};

export function shouldRefreshData(shouldRefresh) {
  return dispatch => {
    dispatch({
      type: TYPES.REFRESH,
      payload: {
        refresh: shouldRefresh
      }
    });
  };
}

export function select(id, select) {
  return dispatch => {
    dispatch({
      type: TYPES.SELECT,
      payload: {
        select,
        id
      }
    });
  };
}

export function search(searchString, columnId) {
  return dispatch => {
    dispatch({
      type: TYPES.SEARCH_STRING,
      payload: {
        searchString,
        columnId
      }
    });
  };
}
function paginateRight(state, totalRecords) {
  const currentPage = state.currentPage;
  const numItems = totalRecords;
  const adjustedLimit = Math.min(numItems, state.limit);
  const maxPages = adjustedLimit === 0 ? 0 : Math.ceil(numItems / adjustedLimit);
  const nextPage = Math.min(currentPage + 1, maxPages);

  const limits = getLimits(totalRecords, nextPage, state.limit);
  if (currentPage === limits.MAXPAGES) {
    return state;
  }
  return {
    ...state,
    currentPage: nextPage,
    showingEnd: limits.END,
    showingStart: limits.START,
    maxPages: limits.MAXPAGES
  };
}

function paginateLeft(state, totalRecords) {
  const currentPage = state.currentPage;
  if (currentPage === 1) {
    return state;
  }
  const nextPage = Math.max(currentPage - 1, 1);
  const limits = getLimits(totalRecords, nextPage, state.limit);

  return {
    ...state,
    currentPage: nextPage,
    showingEnd: limits.END,
    showingStart: limits.START,
    maxPages: limits.MAXPAGES
  };
}

export function getLimits(items, currentPage, limit) {
  let numOfItems = items;
  let adjustedLimit = Math.min(numOfItems, limit);
  let end = currentPage * adjustedLimit;
  let start = end - adjustedLimit + 1;
  let maxPages = adjustedLimit === 0 ? 0 : Math.ceil(numOfItems / adjustedLimit);
  return {
    LIMIT: adjustedLimit,
    START: Math.min(start, numOfItems),
    END: Math.min(end, numOfItems),
    MAXPAGES: maxPages
  };
}

export function changeDataLimit(limit, totalRecords, shouldRefresh = false, changeComputedLimit = true) {
  return dispatch => {
    dispatch({
      type: TYPES.CHANGE_LIMIT,
      payload: {
        limit,
        totalRecords,
        shouldRefresh,
        changeComputedLimit
      }
    });
  };
}

export function paginateNext(totalRecords) {
  return dispatch => {
    dispatch({
      type: TYPES.PAGINATE_RIGHT,
      payload: { totalRecords: totalRecords }
    });
  };
}

export function paginatePrev(totalRecords) {
  return dispatch => {
    dispatch({
      type: TYPES.PAGINATE_LEFT,
      payload: { totalRecords: totalRecords }
    });
  };
}

export function paginationDataReset() {
  return dispatch => {
    dispatch({
      type: TYPES.RESET
    });
  };
}

export function showColumns(identifier, show) {
  return dispatch => {
    dispatch({
      type: TYPES.SHOW_COLUMNS,
      payload: {
        identifier,
        show
      }
    });
  };
}

export function receivedPaginationData(data, totalRecords, isSearchResult = false) {
  return dispatch => {
    dispatch({
      type: PAGINATION_DATA_RECIEVED,
      payload: {
        data,
        totalRecords,
        isSearchResult
      }
    });
  };
}
