import { logout } from './user.js';
import persistor from '../index';
import { REHYDRATE } from 'redux-persist/constants';
import { toggleNotification } from './notification';
import { API_ROOT } from '../components/constants/api';
import { isTokenValid } from '../utils/user';

export const ALL_APIS_DATA_RECIEVED = 'ALL_APIS_DATA_RECIEVED';
export const ALL_APIS_DATA_REQUESTED = 'ALL_APIS_DATA_REQUESTED';
export const SET_SELECTED_API = 'SET_SELECTED_API';

const initialState = {
  data: null,
  selectedApis: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case REHYDRATE:
      persistor.purge();
      return state;
    case ALL_APIS_DATA_RECIEVED:
      let appPayload = action.payload;
      return {
        ...state,
        data: appPayload.apis,
        status: ALL_APIS_DATA_RECIEVED
      };
    case ALL_APIS_DATA_REQUESTED:
      return {
        ...state,
        status: ALL_APIS_DATA_REQUESTED
      };
    case SET_SELECTED_API:
      return {
        ...state,
        selectedApis: action.payload.tempApis
      };
    default:
      return state;
  }
};

export const getSelectedApis = tempApis => {
  return dispatch => {
    dispatch({
      type: SET_SELECTED_API,
      payload: {
        tempApis: tempApis
      }
    });
  };
};

export const getApisData = () => {
  return (dispatch, getState) => {
    dispatch({
      type: ALL_APIS_DATA_REQUESTED
    });
    const tokenExpiry = getState().user.expiresAt;
    if (!isTokenValid(tokenExpiry)) {
      dispatch(logout());
    }
    const apisUrl = API_ROOT + 'list?option=apis';
    const token = getState().user.token;
    const options = {
      method: 'GET',
      headers: {
        Xauthtoken: token,
        'Content-type': 'application/json'
      }
    };

    const message = 'We could not find apis detail at this moment.';
    fetch(apisUrl, options)
      .then(response => {
        if (response.status >= 400) {
          if (response.status === 403) {
            dispatch(logout(false));
          }
          dispatch(toggleNotification(message));
          return null;
        }
        return response.json();
      })
      .then(json => {
        if (json !== null) {
          dispatch({
            type: ALL_APIS_DATA_RECIEVED,
            payload: {
              apis: json.apis
            }
          });
        }
      });
  };
};
