import { logout } from './user.js';
import { getGroupsData, getDistData, getAllGroupsData } from './groups';
import { getDistrictsData } from './districts';
import { getAppsData } from './applications';
import { receivedPaginationData, paginationDataReset } from './pagination';
import { toggleNotification } from './notification';
import { API_ROOT } from '../components/constants/api';
import { isTokenValid } from '../utils/user';
import { getApisData } from './apis.js';
export const USER_SELECTED = 'USER_SELECTED';
export const USERS = 'USERS';
export const GROUPS = 'GROUPS';
export const USERS_DATA_REQUESTED = 'USERS_DATA_REQUESTED';
export const USERS_DATA_ERROR = 'USERS_DATA_ERROR';
export const USERS_DATA_RECIEVED = 'USERS_DATA_RECIEVED';
export const RESET_USERS_DATA = 'RESET_USERS_DATA';
export const USERS_SORT = 'USERS_SORT';
export const SET_USER_ROW_INDEX = 'SET_USER_ROW_INDEX';
export const UPDATE_USER_GROUPS_DATA_RECIEVED =
  'UPDATE_USER_GROUPS_DATA_RECIEVED';
export const UPDATE_USER_GROUPS_DATA_REQUESTED =
  'UPDATE_USER_GROUPS_DATA_REQUESTED';
export const DELETE_USER_REQUESTED = 'DELETE_USER_REQUESTED';
export const DELETE_USER_RECIEVED = 'DELETE_USER_RECIEVED';
export const TOGGLE_NOTIFICATION_FOR_USERS = 'TOGGLE_NOTIFICATION_FOR_USERS';
export const INVITE_NEW_USER_REQUESTED = 'INVITE_NEW_USER_REQUESTED';
export const INVITED_NEW_USER = 'INVITED_NEW_USER';
export const ACCESS_DENIED_MESSAGE = 'You are not authorized to perform this operation.'
const initialState = {
  isUserSelected: true,
  data: null,
  displayUsers: false,
  sortBy: 'none',
  usersColumnInSort: null,
  editRowIndex: -1,
  totalRecords: 0,
  isNotify: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case USER_SELECTED:
      return {
        ...state,
        isUserSelected: action.payload.isUserSelected
      };
    case USERS_DATA_REQUESTED:
      return {
        ...state,
        status: USERS_DATA_REQUESTED
      };
    case USERS_DATA_ERROR:
      return {
        ...state,
        status: USERS_DATA_ERROR,
        showModal: true
      };
    case USERS_DATA_RECIEVED:
      let payload = action.payload;
      return {
        ...state,
        data: payload.users,
        status: USERS_DATA_RECIEVED,
        displayUsers: true,
        totalRecords: payload.totalRecords
      };
    case RESET_USERS_DATA:
      return {
        ...state,
        data: null,
        displayUsers: false
      };
    case USERS_SORT:
      const order = action.payload.order;
      const key = action.payload.key;
      return performSort(state, order, key);
    case SET_USER_ROW_INDEX:
      return {
        ...state,
        editRowIndex: action.payload.index
      };
    case UPDATE_USER_GROUPS_DATA_REQUESTED:
      return {
        ...state,
        status: UPDATE_USER_GROUPS_DATA_REQUESTED
      };
    case UPDATE_USER_GROUPS_DATA_RECIEVED:
      let index = action.payload.index;
      let tempdata = state.data.slice(0);
      tempdata[index].group_ids = action.payload.data.group_ids;
      if (action.payload.data.display_name) {
        tempdata[index].display_name = action.payload.data.display_name;
      }
      return {
        ...state,
        data: tempdata
      };
    case DELETE_USER_REQUESTED:
      return {
        ...state,
        status: DELETE_USER_REQUESTED
      };
    case DELETE_USER_RECIEVED:
      const id = action.payload.id;
      return deleteUser(state, id);
    case TOGGLE_NOTIFICATION_FOR_USERS:
      return {
        ...state,
        isNotify: !state.isNotify
      };
    case INVITE_NEW_USER_REQUESTED:
      return {
        ...state,
        status: INVITE_NEW_USER_REQUESTED
      };
    case INVITED_NEW_USER:
      return {
        ...state,
        status: INVITED_NEW_USER
      };
    default:
      return state;
  }
};

export function updateUserGroupsData(data, index) {
  return (dispatch, getState) => {
    dispatch({
      type: UPDATE_USER_GROUPS_DATA_REQUESTED
    });
    const token = getState().user.token;
    const usersUpdateUrl = `${API_ROOT}userinfo`;
    const options = {
      method: 'POST',
      headers: {
        Xauthtoken: token,
        'Content-type': 'application/json'
      },
      body: JSON.stringify(data)
    };
    fetch(usersUpdateUrl, options)
      .then(response => {
        if (response.status >= 400) {
          if (response.status === 403) {
            dispatch(logout(false));
          }
          dispatch(toggleNotification(ACCESS_DENIED_MESSAGE));
          return null;
        }
        return response.json();
      })
      .then(json => {
        if (json !== null) {
          dispatch({
            type: UPDATE_USER_GROUPS_DATA_RECIEVED,
            payload: {
              data,
              index
            }
          });
        }
      });
  };
}

export function deleteUserAction(id, start, limit) {
  let data = {
    users: [id]
  };
  return (dispatch, getState) => {
    dispatch({
      type: DELETE_USER_REQUESTED
    });
    const token = getState().user.token;
    const usersUpdateUrl = `${API_ROOT}userinfo`;
    const options = {
      method: 'POST',
      headers: {
        Xauthtoken: token,
        'Content-type': 'application/json'
      },
      body: JSON.stringify(data)
    };
    fetch(usersUpdateUrl, options)
      .then(response => {
        if (response.status >= 400) {
          if (response.status === 403) {
            dispatch(logout(false));
          }
          dispatch(toggleNotification(ACCESS_DENIED_MESSAGE));
          return null;
        }
        return response.json();
      })
      .then(json => {
        if (json !== null) {
          dispatch(getUsersData(true, start, limit));
          dispatch(toggleSnackbar());
          setTimeout(() => dispatch(toggleSnackbar()), 3000);
          dispatch({
            type: DELETE_USER_RECIEVED,
            payload: {
              id
            }
          });
        }
      });
  };
}

export function deleteUser(state, userId) {
  const users = state.data.filter(user => {
    return user.email_id !== userId;
  });
  return {
    ...state,
    data: users
  };
}

export function performSort(state, order, key) {
  const sortState = {
    usersColumnInSort: order === 'none' ? null : key,
    sortBy: order
  };
  return {
    ...state,
    ...sortState
  };
}

export function usersSort(key, order) {
  return dispatch => {
    dispatch({
      type: USERS_SORT,
      payload: {
        key,
        order
      }
    });
  };
}

export function setEditUserRowIndex(index) {
  return dispatch => {
    dispatch({
      type: SET_USER_ROW_INDEX,
      payload: {
        index: index
      }
    });
  };
}

export function toggleSnackbar() {
  return dispatch => {
    dispatch({
      type: TOGGLE_NOTIFICATION_FOR_USERS
    });
  };
}

export function getUsersAndGroupsData(paginationState) {
  return dispatch => {
    dispatch(
      getUsersData(true, paginationState.showingStart, paginationState.limit)
    );
    dispatch(
      getGroupsData(true, paginationState.showingStart, paginationState.limit)
    );
    dispatch(getDistrictsData());
    dispatch(getAppsData());
    dispatch(getApisData())
  };
}

export function handleRadioButton(id) {
  return dispatch => {
    let isUserSelected = true;
    dispatch(paginationDataReset());
    if (id === USERS) {
      dispatch(getUsersData(true, 1, 10));
    } else {
      isUserSelected = false;
      dispatch(getGroupsData(true, 1, 10));
    }

    dispatch({
      type: USER_SELECTED,
      payload: {
        isUserSelected: isUserSelected
      }
    });
  };
}

export const getUsersData = (
  isInitialLoad,
  start,
  limit,
  searchData = null
) => {
  return (dispatch, getState) => {
    dispatch({
      type: USERS_DATA_REQUESTED
    });
    let tokenExpiry = getState().user.expiresAt;
    if (!isTokenValid(tokenExpiry)) {
      dispatch(logout());
    }
    let sortField = getState().users.usersColumnInSort;
    let orderby = getState().users.sortBy;
    const sortFieldParam = sortField === null ? '' : `&sort_field=${sortField}`;
    const sortOrder =
      orderby === 'none' ? '&sort_order=asc' : `&sort_order=${orderby}`;
    const limitParam = `&limit=${limit}`;
    const startParam = `start=${start}`;
    const searchParam =
      searchData === null || searchData.trim() === ''
        ? ''
        : `&searchstring=${searchData}`;

    let usersUrl =
      `${API_ROOT}userinfo?` +
      `${startParam}${limitParam}` +
      `${sortOrder}${sortFieldParam}` +
      `${searchParam}`;

    let token = getState().user.token;
    let options = {
      headers: {
        Xauthtoken: token
      }
    };
    let serverErrMsg = 'We could not find users at this moment.';
    let message = 'Sorry! User does not exist';
    fetch(usersUrl, options)
      .then(response => {
        if (response.status >= 400) {
          if (response.status === 403) {
            dispatch(logout(false));
          }
          else {
            if (response.status === 400) {
              dispatch(toggleNotification(message));
            } else {
              dispatch(toggleNotification(serverErrMsg));
            }
          }
          return null;
        }
        return response.json();
      })
      .then(json => {
        let totalNum = 0;
        let users = {};
        
        if (json !== null && totalNum !== undefined) {
          totalNum = json.total_num;
          users = json.users;
          if (totalNum === 0) {
            dispatch({
              type: USERS_DATA_RECIEVED,
              payload: {
                users: [],
                totalRecords: 0
              }
            });
            dispatch(receivedPaginationData([], 0));
            return;
          }
          // Get District data from API for unique group ids
          let uniqueGroups = getUniqueGroupIds(users);
          let districtReq = {
            groups: uniqueGroups,
            op: 'fetch'
          };
          if (uniqueGroups.length > 0) {
            dispatch(getDistData(districtReq));
          }
          // update reducer for user data
          dispatch({
            type: USERS_DATA_RECIEVED,
            payload: {
              users: users,
              totalRecords: totalNum
            }
          });
          dispatch(getAllGroupsData(uniqueGroups));
          if (isInitialLoad) {
            const isSearch = searchData !== null;
            dispatch(receivedPaginationData(users, totalNum, isSearch));
          }
          if (start > totalNum) {
            dispatch(getUsersData(true, start - limit, limit));
          }
        }
      });
  };
};

export function inviteNewUser(data) {
  return (dispatch, getState) => {
    dispatch({
      type: INVITE_NEW_USER_REQUESTED
    });
    let inviteNewUserUrl = API_ROOT + 'invite';
    const token = getState().user.token;
    let options = {
      method: 'POST',
      headers: {
        Xauthtoken: token,
        'Content-type': 'application/json'
      },
      body: JSON.stringify(data)
    };
    let message = 'We could not invite new user at this moment.';
    fetch(inviteNewUserUrl, options)
      .then(response => {
        if (response.status >= 400) {
          if (response.status === 403) {
            dispatch(logout(false));
          }
          dispatch(toggleNotification(message));
          return null;
        }
        return response.json();
      })
      .then(json => {
        if (json !== null) {
          dispatch({
            type: INVITED_NEW_USER
          });
        }
      });
  };
}

export function getUniqueGroupIds(usersData) {
  let groups = [];
  usersData.forEach(user => {
    groups = Array.from(new Set([...groups, ...user.group_ids]));
  });
  return groups;
}
