import moment from 'moment';

export function shouldRefreshToken(expiryTime) {
  if (Date.now() > new Date(expiryTime).getTime() * 1000) {
    return true;
  }
  return false;
}

export function isTokenValid(tokenExpiry = null) {
  if (tokenExpiry === null) {
    return false;
  }
  let expiryTime = moment.utc(tokenExpiry, 'YYYY-MM-DDTHH:mm:ss').local();
  let currentTimeInUTC = moment.utc().local();
  let isValid = expiryTime.isAfter(currentTimeInUTC);
  return isValid;
}

export function isTokenInLocalStorageValid() {
  let user = JSON.parse(window.localStorage.getItem('reduxPersist:user'));
  if (user !== null && user.expiresAt !== null && user.token !== null) {
    let tokenExpiry = user.expiresAt;
    if (!tokenExpiry) {
      return false;
    }
    let expiryTime = moment.utc(tokenExpiry, 'YYYY-MM-DDTHH:mm:ss').local();
    let currentTimeInUTC = moment.utc().local();
    let isValid = expiryTime.isAfter(currentTimeInUTC);
    return isValid;
  }
  return false;
}
