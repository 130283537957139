import React from 'react';
import { css } from 'emotion';

export function getUserDeleteConfirmationMessage(userName) {
  const areYouSure = 'Are you sure that you want to delete ';
  const userNameCopy = `${userName} - copy`;
  const action = '? This action ';
  const undone = 'cannot be undone, ';
  const deleteMsg = 'and will permanently delete your user';
  return [
    <span className={regularMsg} key="1">
      {areYouSure}
    </span>,
    <span className={boldMsg} key="2">
      {userNameCopy}
    </span>,
    <span className={regularMsg} key="3">
      {action}
    </span>,
    <span className={boldMsg} key="4">
      {undone}
    </span>,
    <span className={regularMsg} key="5">
      {deleteMsg}
    </span>
  ];
}

export function getGroupDeleteConfirmationMessage(groupName) {
  const areYouSure = 'Are you sure that you want to delete ';
  const groupNameCopy = `${groupName}?`;
  const action = ' This action ';
  const undone = 'cannot be undone, ';
  const deleteMsg = 'and will permanently delete your group and its details.';
  return [
    <span className={regularMsg} key="1">
      {areYouSure}
    </span>,
    <span className={boldMsg} key="2">
      {groupNameCopy}
    </span>,
    <span className={regularMsg} key="3">
      {action}
    </span>,
    <span className={boldMsg} key="4">
      {undone}
    </span>,
    <span className={regularMsg} key="5">
      {deleteMsg}
    </span>
  ];
}

const regularMsg = css({
  fontFamily: 'Open Sans',
  fontSize: '14px',
  fontStyle: 'normal',
  fontStretch: 'normal',
  lineHeight: 1.5,
  letterSpacing: 'normal',
  color: '#5e6c84'
});

const boldMsg = css({
  fontFamily: 'Open Sans',
  fontSize: '14px',
  fontStyle: 'normal',
  fontStretch: 'normal',
  lineHeight: 1.5,
  letterSpacing: 'normal',
  color: '#5e6c84',
  fontWeight: 600
});
