import React, { Component } from 'react';
import { css } from 'emotion';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import UsersTable from '../../components/users/users-table';
import UsersEditor from '../users/users-editor';
import {
  setEditGroupRowIndex,
  updateGroupData,
  refreshAccessCode,
  createGroup
} from '../../modules/groups';
import Button from '../button/button';
import RowResizer from '../resizer/row-resizer';
import Pagination from '../pagination/pagination';
import NotificationModal from '../modal/notification-modal';
import Snackbar from '../notification/snackbar';
import {
  USERS,
  GROUPS,
  handleRadioButton,
  usersSort,
  getUsersAndGroupsData,
  deleteUserAction,
  getUsersData,
  inviteNewUser
} from '../../modules/users';
import { fetchAllGroups } from '../../modules/groupsScroll';
import {
  groupsSort,
  getGroupsData,
  deleteGroupAction
} from '../../modules/groups';
import { toggleNotification } from '../../modules/notification';
import {
  paginateNext,
  paginatePrev,
  changeDataLimit,
  shouldRefreshData,
  paginationDataReset
} from '../../modules/pagination';
import GroupsTable from '../groups/groups-table';
import InviteNewUserModal from '../modal/invite-new-user-modal';
import CreateNewGroupModal from '../modal/create-new-group';
import { debounce } from 'lodash';
import SearchIcon from '../../assets/search-icon.svg';
import { getSelectedDistrictIds } from '../../modules/districts';
import { getSelectedApps } from '../../modules/applications';
import { getSelectedApis } from '../../modules/apis';
class UsersPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowType: 'medium',
      showInviteModal: false,
      searchKey: '',
      isSearchActive: false
    };
  }
  componentDidMount() {
    this.props.getUsersAndGroupsData(this.props.pagination);
  }
  buttonHandler = () => {
    return null;
  };

  onRowSizeChanged = type => {
    this.setState({
      rowType: type
    });
  };

  showNotificationModal = () => {
    let heading = 'Error';
    let okButtonTitle = 'OK';
    if (this.props.notification.showModal === false) {
      return null;
    }
    return (
      <NotificationModal>
        <div className={notificationContainer}>
          <div className={headingClass}>{heading}</div>
          <div className={notitficationTitle}>
            {this.props.notification.data}
          </div>
          <div className={notificationButtons}>
            <Button
              btnClassName={notificationOKButton}
              buttonTextStyle={OKButtonText}
              text={okButtonTitle}
              buttonContainerStyle={buttonContainerStyle}
              buttonClickCallback={this.props.toggleNotification}
            />
          </div>
        </div>
      </NotificationModal>
    );
  };

  toggeleInviteModal = () => {
    this.setState({
      showInviteModal: !this.state.showInviteModal
    });
  };

  searchForUsers = debounce(value => {
    const { computedLimit } = this.props.pagination;
    this.props.getUsersData(true, 1, computedLimit, value);
  }, 500);

  searchForGroups = debounce(value => {
    const { computedLimit } = this.props.pagination;
    this.props.getGroupsData(true, 1, computedLimit, value);
  }, 500);

  handleSearchButton = event => {
    if (this.props.users.isUserSelected) {
      this.searchForUsers(event.target.value);
      this.setState({
        searchKey: event.target.value,
        isSearchActive: event.target.value !== '' ? true : false
      });
    } else {
      this.searchForGroups(event.target.value);
      this.setState({
        searchKey: event.target.value,
        isSearchActive: event.target.value !== '' ? true : false
      });
    }
  };

  resetSearchKey = () => {
    this.setState({ searchKey: '', isSearchActive: false });
  };
  showdInviteNewUserModal = () => {
    let title = 'Invite New User';
    let subtitle =
      'Enter the email address of the person you would like to invite';
    let message =
      "By clicking 'Send Invite' you would be allowing this person to join your team. You can edit the permissions of this user later";
    if (this.state.showInviteModal && this.props.users.isUserSelected) {
      return (
        <InviteNewUserModal
          title={title}
          subtitle={subtitle}
          message={message}
          toggeleInviteNewUserModal={this.toggeleInviteModal}
          inviteNewUser={this.props.inviteNewUser}
        />
      );
    }
    if (this.state.showInviteModal && !this.props.users.isUserSelected) {
      return (
        <CreateNewGroupModal
          toggeleCreateNewGroupModal={this.toggeleInviteModal}
          districtsData={this.props.districts}
          appsData={this.props.apps}
          apisData={this.props.apis}
          createGroup={this.props.createGroup}
          getSelectedDistrictIds={this.props.getSelectedDistrictIds}
          getSelectedApps={this.props.getSelectedApps}
          getSelectedApis={this.props.getSelectedApis}
        />
      );
    }
  };

  paginationData = () => {
    const { users } = this.props;
    if (this.state.isSearchActive === false) {
      if (users.isUserSelected === true) {
        return this.props.getUsersData;
      } else {
        return this.props.getGroupsData;
      }
    } else {
      if (users.isUserSelected === true) {
        return this.props.getUsersData;
      } else {
        return this.props.getGroupsData;
      }
    }
  };
  renderUsers() {
    const { limit, showingStart, refresh } = this.props.pagination;
    const { rowType } = this.state;
    const { usersSort, users, getUsersData, groupsScroll } = this.props;
    const isUserSelected = users.isUserSelected;
    let data = JSON.parse(JSON.stringify(users.data));
    let groupsData = groupsScroll;
    if (data !== null && isUserSelected) {
      return (
        <UsersTable
          groups={this.props.groups}
          groupsData={groupsData}
          data={data}
          rowType={rowType}
          limit={limit}
          usersSort={usersSort}
          start={showingStart}
          getUsersData={getUsersData}
          sortBy={users.sortBy}
          changeDataLimit={this.props.changeDataLimit}
          totalRecords={this.props.users.totalRecords}
          isInSort={users.usersColumnInSort}
          editRowIndex={this.props.users.editRowIndex}
          getGroupsData={this.props.getGroupsData}
          deleteUser={this.props.deleteUserAction}
          fetchAllGroups={this.props.fetchAllGroups}
          shouldRefreshData={this.props.shouldRefreshData}
          refresh={refresh}
          searchKey={this.state.searchKey}
        />
      );
    }
    return null;
  }

  renderGroups() {
    const { limit, showingStart, refresh } = this.props.pagination;
    const { rowType } = this.state;
    const {
      groupsSort,
      groups,
      refreshAccessCode,
      districts,
      getGroupsData
    } = this.props;
    const displayGroups = !this.props.users.isUserSelected;
    let data = JSON.parse(JSON.stringify(groups.data));
    let districtsData = JSON.parse(JSON.stringify(districts.data));

    if (data !== null && displayGroups) {
      return (
        <GroupsTable
          data={data}
          districtsData={districtsData}
          rowType={rowType}
          limit={limit}
          start={showingStart}
          getGroupsData={getGroupsData}
          sortBy={groups.sortBy}
          groupsSort={groupsSort}
          refreshAccessCode={refreshAccessCode}
          changeDataLimit={this.props.changeDataLimit}
          isInSort={groups.groupsColumnInSort}
          editRowIndex={groups.editRowIndex}
          updateGroupData={this.props.updateGroupData}
          setEditGroupRowIndex={this.props.setEditGroupRowIndex}
          deleteGroup={this.props.deleteGroupAction}
          totalRecords={this.props.groups.totalRecords}
          shouldRefreshData={this.props.shouldRefreshData}
          refresh={refresh}
          searchKey={this.state.searchKey}
          appsData={this.props.apps}
          apisData={this.props.apis}
        />
      );
    }
    return null;
  }

  renderBottomBar() {
    const {
      currentPage,
      showingEnd,
      showingStart,
      limit,
      computedLimit,
      maxPages
    } = this.props.pagination;

    const { users, groups } = this.props;
    const numUserRecords = users.totalRecords;
    const numGroupRecords = groups.totalRecords;
    const totalRecords =
      users.isUserSelected === true ? numUserRecords : numGroupRecords;

    const bottomBarStyle = `${bottom} ${anchorDown}`;
    const showBottomBar = users.displayUsers || groups.displayGroups;
    if (showBottomBar) {
      return (
        <div className={bottomBarStyle}>
          <div className={rowResizerStyle}>
            <RowResizer
              onRowSizeChanged={this.onRowSizeChanged}
              changeDataLimit={this.props.changeDataLimit}
              totalRecords={totalRecords}
              shouldRefreshData={this.props.shouldRefreshData}
            />
          </div>
          <Pagination
            right={this.props.paginateNext}
            left={this.props.paginatePrev}
            reset={this.props.paginationDataReset}
            curPage={currentPage}
            start={showingStart}
            end={showingEnd}
            max={maxPages}
            limit={limit}
            defaultLimit={computedLimit}
            changeDataLimit={this.props.changeDataLimit}
            isSearchActive={this.state.isSearchActive}
            searchKey={this.state.searchKey}
            getData={this.paginationData()}
            totalRecords={totalRecords}
          />
        </div>
      );
    }
    return null;
  }
  render() {
    const selection = this.props.users.isUserSelected === true ? USERS : GROUPS;
    let btnText =
      this.props.users.isUserSelected === true
        ? 'INVITE NEW USER'
        : 'CREATE NEW GROUP';
    return (
      <div className={container}>
        {this.showNotificationModal()}
        {this.showdInviteNewUserModal()}
        <div className={topRow}>
          <div className={lhs}>
            <UsersEditor
              selected={selection}
              resetSearchKey={this.resetSearchKey}
            />
          </div>
          <div className={middle}>
            <input
              className={searchStyle}
              type="text"
              id="iconified"
              style={{
                paddingLeft: '50px',
                backgroundImage: 'url(' + SearchIcon,
                backgroundRepeat: 'no-repeat'
              }}
              placeholder="Search..."
              value={this.state.searchKey}
              onChange={this.handleSearchButton.bind(this)}
            />
            {this.props.users.isNotify ? (
              <Snackbar msg="User has been successfully deleted" />
            ) : null}
            {this.props.groups.isNotify ? (
              <Snackbar msg="Group has been successfully deleted" />
            ) : null}
          </div>
          <div>
            <Button
              text={btnText}
              buttonClickCallback={this.toggeleInviteModal}
              buttonTextStyle={InviteButtonText}
              btnClassName={btnClassName}
              buttonContainerStyle={InputbuttonContainerStyle}
            />
          </div>
        </div>
        <div className={row}>
          {this.renderUsers()}
          {this.renderGroups()}
        </div>
        {this.renderBottomBar()}
      </div>
    );
  }
}
const container = css({
  width: '100%',
  height: '80px',
  boxShadow: '0 2px 6px 0 rgba(0, 0, 0, 0.1)',
  backgroundColor: '#ffffff'
});

const topRow = css({
  fontFamily: 'Open Sans',
  fontSize: '16px',
  fontWeight: 600,
  fontStyle: 'normal',
  fontStretch: 'normal',
  lineHeight: '1.5',
  letterSpacing: '1px',
  textAlign: 'center',
  color: '#112138',
  display: 'grid',
  gridTemplateColumns: '1fr 4fr auto',
  padding: '18px 23px'
});

const lhs = css({
  alignSelf: 'center'
});

const bottom = css({
  display: 'flex',
  height: '64px',
  boxShadow: '0 2px 20px 0 rgba(0, 0, 0, 0.1)',
  width: '100%',
  backgroundColor: 'white'
});

const anchorDown = css({
  position: 'fixed',
  bottom: 0
});

const rowResizerStyle = css({
  display: 'flex',
  marginLeft: '20px'
});

const row = css({
  display: 'flex'
});

const headingClass = css({
  fontFamily: 'Open Sans',
  fontSize: '18px',
  fontWeight: 600,
  fontStyle: 'normal',
  fontStretch: 'normal',
  lineHeight: 1.5,
  letterSpacing: 'normal',
  color: '#112138',
  padding: ' 15px 24px',
  backgroundColor: '#f4f5f7'
});

const notificationContainer = css({
  display: 'flex',
  flexDirection: 'column'
});

const notitficationTitle = css({
  display: 'flex',
  fontFamily: 'Open Sans',
  fontSize: '14px',
  fontWeight: 'normal',
  lineHeight: 1.5,
  letterSpacing: 'normal',
  textAlign: 'left',
  color: '#5e6c84',
  padding: '20px'
});
const searchStyle = css({
  width: '286px',
  height: '46px',
  textAlign: 'left',
  display: 'table',
  fontFamily: 'Open Sans',
  fontSize: '14px',
  fontWeight: 'normal',
  fontStyle: 'normal',
  fontStretch: 'normal',
  lineHeight: 1.71,
  letterSpacing: 'normal',
  backgroundColor: '#ffffff',
  borderRadius: '2px',
  border: 'solid 1px #dfe1e5',
  outline: 'none',
  color: '#5e6c84',
  '::-webkit-input-placeholder': {
    color: '#c1c7d0'
  },
  '&:hover': { border: 'solid 1.5px #0151cb' }
});
const notificationButtons = css({
  display: 'flex',
  justifyContent: 'center',
  marginTop: '25px'
});

const notificationOKButton = css({
  height: '48px',
  borderRadius: '2px',
  backgroundColor: 'rgba(23, 44, 76, 0.2)',
  border: 'solid 1px #c4cdd5',
  ':hover': {
    border: 'solid 1px rgba(23, 44, 76, 0.2)'
  }
});

const OKButtonText = css({
  fontFamily: 'Open Sans',
  fontSize: '14px',
  fontWeight: 'bold',
  fontStyle: 'normal',
  fontStretch: 'normal',
  lineHeight: 1.5,
  letterSpacing: '1px',
  textAlign: 'center',
  color: '#ffffff'
});

const InviteButtonText = css({
  fontFamily: 'Open Sans',
  fontSize: '14px',
  fontWeight: 'bold',
  fontStyle: 'normal',
  fontStretch: 'normal',
  lineHeight: 1.5,
  letterSpacing: '1px',
  textAlign: 'center',
  color: '#ffffff'
});

const buttonContainerStyle = css({
  height: '48px',
  borderRadius: '2px',
  backgroundColor: '#0151cb'
});

const InputbuttonContainerStyle = css({
  width: '186px',
  height: '48px',
  borderRadius: '2px',
  backgroundColor: '#0151cb'
});

const middle = css({
  display: 'flex',
  fontFamily: 'Open Sans',
  flexDirection: 'row-reverse',
  color: 'rgb(0, 0, 0)',
  padding: '0 20px 0 20px'
});

const btnClassName = css({
  width: '186px',
  height: '48px',
  padding: 0
});

const mapStateToProps = state => ({
  users: state.users,
  groups: state.groups,
  districts: state.districts,
  apps: state.apps,
  apis: state.apis,
  pagination: state.pagination,
  notification: state.notification,
  groupsScroll: state.groupsScroll
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      paginateNext,
      paginatePrev,
      changeDataLimit,
      paginationDataReset,
      handleRadioButton,
      toggleNotification,
      usersSort,
      groupsSort,
      getGroupsData,
      getUsersAndGroupsData,
      setEditGroupRowIndex,
      updateGroupData,
      deleteUserAction,
      deleteGroupAction,
      refreshAccessCode,
      getUsersData,
      fetchAllGroups,
      createGroup,
      inviteNewUser,
      shouldRefreshData,
      getSelectedDistrictIds,
      getSelectedApps,
      getSelectedApis
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(UsersPage);
