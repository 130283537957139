import React, { Component } from 'react';
import DropDown from './dropDown';
import Option from '../option/option';
import { css } from 'emotion';
class ApplicationsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tempApps: [], // this variable is going to hold temporary data so
      //that upon clicking on cancel button we will not have any impact on old data

      showApps: false,
      displayList: false
    };
    this.onOptionSelected = this.onOptionSelected.bind(this);
  }

  appList = []; //this variable will hold group ids of groups.

  componentDidMount() {
    if (this.appList !== undefined) {
      this.setState({
        tempApps: this.appList.slice(0)
      });
    }
  }
  onOptionSelected(id, checked) {
    let clickedApps = this.props.appsData.data[id];
    let appsCopy;
    if (checked) {
      appsCopy = this.state.tempApps.slice(0);
      appsCopy.push(clickedApps);
    } else {
      appsCopy = this.state.tempApps.filter(value => value !== clickedApps);
    }
    this.setState({
      tempApps: appsCopy
    }, () =>{
      this.props.getSelectedApps(this.state.tempApps)
    });
  }
  renderApps = () => {
    return (
      <div className={appData + ' thin-vert-scrollbar'}>
        {this.props.appsData.data.map((app, index) => {
          return (
            <Option
              Name={app}
              isSelected={this.state.tempApps.includes(app)}
              key={index}
              onSelect={this.onOptionSelected}
              keyId={index}
            />
          );
        })}
      </div>
    );
  };

  renderSelectedApps = () => {
    if (this.state.tempApps.length === 0) {
      return <div className={textStyle}>Add Applications</div>;
    }
    return this.state.tempApps.map((apps, index) => {
      return (
        <div className={appCell} key={index}>
          {apps}
        </div>
      );
    });
  };
  toggleArrows = () => {
    this.setState({
      displayList: !this.state.displayList
    });
  };

  handleAddApps = () => {
    this.toggleArrows();
    this.setState({
      showApps: !this.state.showApps
    });
  };

  render() {
    return (
      <div>
        <DropDown
          handleArrow={this.handleAddApps}
          renderSelectedItems={this.renderSelectedApps}
          displayList={this.state.displayList}
        />
        {this.state.showApps ? this.renderApps(this.props) : null}{' '}
      </div>
    );
  }
}
const appData = css({
  width: '320px',
  height: '336px',
  overflowY: 'scroll',
  position: 'fixed',
  top: '270px',
  zIndex: 1,
  background: '#ffffff',
  padding: '8px',
  margin: '0 16px 16px 16px',
  borderRadius: '2px',
  boxShadow: '0 3px 10px 0 rgba(0, 0, 0, 0.09)',
  border: 'solid 1px #ebecf0'
});

const textStyle = css({
  paddingLeft: '16px',
  fontFamily: 'Open Sans',
  fontSize: '14px',
  fontWeight: 'normal',
  fontStyle: 'normal',
  fontStretch: 'normal',
  lineHeight: 1.5,
  letterSpacing: 'normal',
  color: '#c1c7d0'
});

const appCell = css({
  borderRadius: '2px',
  border: 'solid 1px #ebecf0',
  backgroundColor: '#ffffff',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  fontFamily: 'Open Sans',
  fontSize: '14px',
  fontStyle: 'normal',
  fontStretch: 'normal',
  lineHeight: '1.5',
  letterSpacing: 'normal',
  color: '#6c798f',
  textAlign: 'left',
  minWidth: '84px',
  minHeight: '32px',
  alignItems: 'center',
  margin: '5px'
});
export default ApplicationsList;
