import React, { Component } from 'react';
import upArrow from '../../assets/sort-asc-arrow.svg';
import downArrow from '../../assets/sort-desc-arrow.svg';
import { css } from 'emotion';
class DropDown extends Component {
  displayArrows = () => {
    return (
      <img
        className={imgStyle}
        src={this.props.displayList ? upArrow : downArrow}
        alt=''
        onClick={this.props.handleArrow}
      />
    );
  };
  render() {
    const divStyle =
    this.props.requiredStyle && this.props.data.length === 0
    ? requiredListContainer
    : listContainer;
    return (
      <div className={divStyle}>
        <div
          className={addDistContainer + ' thin-horiz-scrollbar'}
          onClick={this.props.handleArrow}
        >
          {this.props.renderSelectedItems()}
        </div>
        {this.displayArrows()}
      </div>
    );
  }
}
const imgStyle = css({
  padding: '10px'
});
const listContainer = css({
  display: 'flex',
  height: '56px',
  margin: '0 16px 16px 16px',
  flexDirection: 'row',
  borderRadius: '2px',
  border: 'solid 1px #dfe1e5',
  backgroundColor: '#ffffff'
});
  const requiredListContainer = css({
    display: 'flex',
    height: '56px',
    margin: '0 16px 16px 16px',
    flexDirection: 'row',
    borderRadius: '2px',
    border: 'solid 1px #ff5630',
    backgroundColor: '#ffffff'
  });
const addDistContainer = css({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  overflowY: 'scroll',
  minWidth: '90%',
  maxWidth: '90%'
});
export default DropDown;
