import React, { Component } from 'react';
import { css } from 'emotion';
import emailIcon from '../../assets/email-confirmation.svg';

class EmailConfirmationModal extends Component {
  render() {
    return (
      <div className={modalClass}>
        <div className={modalMain}>
          <div className={imgStyle}>
            <img src={emailIcon} alt="" />
          </div>
          <div className={container}>
            <span className={title}>{this.props.title}</span>
          </div>
          <div className={msgcontainer}>
            <span className={messageStyle}>
              {this.props.message}
              {this.props.email}
            </span>
          </div>
          <div className={btn}>
            <div
              className={closeBtn}
              onClick={() => this.props.toggleSendInviteModal}
            >
              CLOSE
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const modalClass = css({
  position: 'absolute',
  zIndex: 1,
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',
  background: 'rgba(0, 0, 0, 0.6)'
});
const modalMain = css({
  position: 'absolute',
  background: 'white',
  width: '364px',
  minHeight: '355px',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%,-50%)',
  borderRadius: '4px',
  boxShadow: '0 10px 10px 0 rgba(0, 0, 0, 0.18)'
});

const container = css({
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center',
  width: '100%',
  height: '48px'
});

const msgcontainer = css({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  height: '48px'
});

const title = css({
  paddingLeft: '16px',
  fontFamily: 'Open Sans',
  fontSize: '16px',
  fontWeight: 700,
  fontStyle: 'normal',
  fontStretch: 'normal',
  lineHeight: 1.5,
  letterSpacing: 'normal',
  color: '#112138'
});
const messageStyle = css({
  width: '332px',
  paddingLeft: '16px',
  fontFamily: 'Open Sans',
  fontSize: '14px',
  fontWeight: 'normal',
  fontStyle: 'normal',
  fontStretch: 'normal',
  lineHeight: 1.5,
  letterSpacing: 'normal',
  color: '#5e6c84'
});

const btn = css({
  display: 'flex',
  justifyContent: 'space-evenly',
  marginTop: '10px',
  alignItems: 'center',
  width: '364px',
  height: '56px',
  borderRadius: '2px',
  backgroundColor: '#fafbfc'
});

const closeBtn = css({
  width: '100%',
  padding: '16px',
  border: 'solid 1px #ebecf0',
  fontFamily: 'Open Sans',
  fontSize: '14px',
  fontWeight: '700',
  fontStyle: 'normal',
  fontStretch: 'normal',
  lineHeight: 1.5,
  letterSpacing: '1px',
  textAlign: 'center',
  color: '#0151cb',
  cursor: 'pointer'
});

const imgStyle = css({
  display: 'flex',
  justifyContent: 'space-evenly',
  width: '100%',
  minHeight: '193px',
  backgroundColor: '#deebff'
});
export default EmailConfirmationModal;
