import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import app from './app';
import user from './user';
import users from './users';
import groups from './groups';
import pagination from './pagination';
import notification from './notification';
import districts from './districts';
import apps from './applications';
import apis from './apis';
import groupsScroll from './groupsScroll';
export default combineReducers({
  routing: routerReducer,
  app,
  user,
  users,
  groups,
  pagination,
  notification,
  districts,
  apps,
  apis,
  groupsScroll
});
