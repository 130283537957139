import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextField from '../textfield/textfield';
import Button from '../button/button';
import { css } from 'emotion';
import loading from '../../assets/loading.gif';

class Login extends Component {
  constructor(props) {
    super(props);
    this.formPreventDefault = this.formPreventDefault.bind(this);
    this.state = {
      username: '',
      password: ''
    };
  }

  handleKeyPress(target) {
    if (target.charCode === 13) {
      this.props.onSubmit(
        this.state.username.toLowerCase(),
        this.state.password.toLowerCase()
      );
    }
  }

  formPreventDefault(e) {
    e.preventDefault();
  }

  render() {
    let loginJSX = this.props.loading ? (
      <img src={loading} alt="loading" className={loadingClass} />
    ) : (
      <Button
        label="Submit"
        className={buttonStyle}
        btnClassName={supplyButtonStyle}
        buttonClickCallback={() =>
          this.props.onSubmit(
            this.state.username.toLowerCase(),
            this.state.password
          )}
        text="LOGIN"
      />
    );
    return (
      <div>
        <form onSubmit={this.formPreventDefault}>
        <div className={divStyle}>
          <TextField
            labelType="floating"
            label="Enter Username"
            value=""
            onChange={(event, newValue) =>
              this.setState({ username: newValue })}
          />
          <br />
          <TextField
            type="password"
            labelType="floating"
            label="Enter Password"
            value=""
            onChange={(event, newValue) =>
              this.setState({ password: newValue })}
            onKeyPress={this.handleKeyPress.bind(this)}
          />
          <br />
          {loginJSX}
        </div>
        </form>
      </div>
    );
  }
}

const buttonStyle = css({
  margin: 15
});
const supplyButtonStyle = css({
  width: '100%'
});
const divStyle = css({
  textAlign: 'center',
  margin: '0 auto'
});

const loadingClass = css({
  height: '50px',
  width: '50px'
});

Login.propTypes = {
  onSubmit: PropTypes.func,
  loading: PropTypes.bool
};
export default Login;
