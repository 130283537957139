import React, { Component } from 'react';
import { css } from 'emotion';
export const Edit = 'Edit';
export const Done = 'Done';

class EditButton extends Component {
  handleEditButtonClick = (index, name) => {
    this.props.handleEditClick(index);
  };

  render() {
    return (
      <div
        className={editBtn}
        key={this.props.index}
        onClick={event =>
          this.handleEditButtonClick(this.props.index, this.props.name)}
      >
        {this.props.editClicked ? Done : Edit}
      </div>
    );
  }
}

const editBtn = css({
  display: 'flex',
  alignItems: 'center',
  width: '50%',
  fontFamily: 'Open Sans',
  fontSize: '14px',
  fontWeight: 700,
  fontStyle: 'normal',
  fontStretch: 'normal',
  lineHeight: '1.57',
  letterSpacing: 'normal',
  textAlign: 'left',
  color: '#0151cb',
  cursor: 'pointer',
  textTransform: 'uppercase'
});

export default EditButton;
