import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { css } from 'emotion';
import PropTypes from 'prop-types';
import FybrLogo from '../../fybr-logo.svg';

const navLinks = [
  {
    name: 'User Management UI',
    id: 'user-management',
    href: '/'
  }
];

export default class AppHeader extends Component {
  logout() {
    this.props.logoutAction();
    this.props.goToLoginPage();
  }

  renderLogoutButton() {
    let isUserLoggedIn = this.props.user.isLoggedIn;
    if (isUserLoggedIn) {
      return (
        <div className={rightAligned} onClick={this.logout.bind(this)}>
          LOGOUT
        </div>
      );
    }
    return null;
  }

  render() {
    let btn = this.props.button.name ? (
      <div className={rightAligned} onClick={this.props.button.func}>
        {this.props.button.name}
      </div>
    ) : (
      <div className={rightAligned} onClick={this.logout.bind(this)}>
        LOGOUT
      </div>
    );
    return (
      <div>
        <div className={appHeaderClass}>
          <div className={navBar}>
            <Link to="/">
              <img className={logo} alt="Fybr Logo" src={FybrLogo} />
            </Link>
            <hr className={verticalSeperator} />
            {navLinks.map(link => (
              <li className={links} key={link.id}>
                <Link to={link.href}>{link.name}</Link>
              </li>
            ))}
            {btn}
          </div>
        </div>
        <div className={marginBar} />
      </div>
    );
  }
}

const appHeaderClass = css({
  background: '#202e38',
  color: '#FFFFFF',
  position: 'fixed',
  padding: '20px',
  fontSize: '15px',
  fontWeight: '500',
  top: '0',
  height: '60px',
  width: '100%',
  boxSizing: 'border-box',
  zIndex: '10'
});

const navBar = css({
  display: 'flex',
  width: '100%',
  margin: '0px'
});

const links = css({
  display: 'inline-block',
  '& a': {
    display: 'inline-block',
    textTransform: 'uppercase',
    color: '#FFFFFF',
    letterSpacing: '0.5px',
    marginRight: '20px',
    lineHeight: '1',
    textDecoration: 'none'
  }
});

const logo = css({
  display: 'block',
  marginRight: '20px'
});

const verticalSeperator = css({
  margin: '0',
  width: '0',
  border: 'solid 1px #979797',
  height: '20px',
  marginTop: '-2.5px',
  marginRight: '20px'
});

const marginBar = css({
  height: '60px',
  width: '100%'
});

const rightAligned = css({
  textTransform: 'uppercase',
  marginLeft: 'auto',
  cursor: 'pointer'
});

AppHeader.propTypes = {
  button: PropTypes.object
};
AppHeader.defaultProps = {
  button: {}
};
