import React, { Component } from 'react';
import { css } from 'emotion';
import upArrow from '../../assets/sort-asc-arrow.svg';
import downArrow from '../../assets/sort-desc-arrow.svg';
import cross from '../../assets/cross.svg';
import EditButton from '../button/edit-button';
import DeleteConfirmation from '../modal/delete-confirmation';
import AddGroupsModal from '../modal/add-groups-modal';
import { getRowHeightFor, getPageDimensions } from '../resizer/utils';
import addIcon from '../../assets/add-icon.svg';
import addIconHover from '../../assets/add-icon-hover.svg';
import { setEditUserRowIndex, updateUserGroupsData } from '../../modules/users';
import { getUserDeleteConfirmationMessage } from '../../utils/styles';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import MappleToolTip from 'reactjs-mappletooltip';
class UsersTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      states: ['none', 'asc', 'desc'],
      index: ['none', 'asc', 'desc'].indexOf(props.sortBy),
      isHovering: false,
      hoverIndex: -1,
      display: false,
      userInput: '',
      showDeleteModalConfirmation: false,
      showAddGroupsModal: false,
      selectedUserId: null,
      data: props.data,
      groupDivWidth: null,
      hoverDistrictId: -1,
      header: {
        USER: {
          width: '15%',
          paddingLeft: '24px',
          displaySort: true,
          mappedField: 'display_name'
        },
        'EMAIL ADDRESS': {
          width: '15%',
          displaySort: true,
          mappedField: 'emailaddr'
        },
        'GROUP ID': {
          width: '25%'
        },
        'DISTRICT ID': {
          width: '37%'
        },
        ACTIONS: {
          width: '8%'
        }
      }
    };
    this.onDeleteClicked = this.onDeleteClicked.bind(this);
    this.onDeleteConfirmed = this.onDeleteConfirmed.bind(this);
    this.onDismissUserDeletion = this.onDismissUserDeletion.bind(this);
    this.groupDivRef = null;
    this.distDivRef = null;
    this.containerNode = null;
    this.onRowClicked = this.onRowClicked.bind(this);
    this.editRowIndex = -1;
  }

  componentWillMount() {
    window.addEventListener('resize', this.resizeThrottler, false);
    document.addEventListener('mousedown', this.handleClick, false);
  }

  componentDidMount() {
    setTimeout(() => {
      this.onResize();
    }, 500);
  }

  componentWillReceiveProps(nextProps) {
    const limitHasChanged =
      parseInt(this.props.limit) !== parseInt(nextProps.limit);
    if (limitHasChanged && nextProps.refresh === true) {
      this.props.getUsersData(
        false,
        this.props.start,
        nextProps.limit,
        nextProps.searchKey
      );
      this.props.shouldRefreshData(false);
    }

    this.setState({
      data: nextProps.data
    });
  }

  resizeThrottler = () => {
    if (!this.resizeTimeout) {
      this.resizeTimeout = setTimeout(() => {
        this.resizeTimeout = null;
        this.onResize();
      }, 66);
    }
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeThrottler, false);
    document.removeEventListener('mousedown', this.handleClick, false);
  }

  onResize = () => {
    const rowHeight = getRowHeightFor(this.props.rowType);
    const dimensions = getPageDimensions(rowHeight);
    const newLimit = dimensions.noOfRows;
    this.props.changeDataLimit(newLimit, this.props.totalRecords);
    this.props.getUsersData(
      false,
      this.props.start,
      newLimit,
      this.props.searchKey
    );
    this.setState({
      rowsHeight: dimensions.remainingHeight,
      groupDivWidth: this.groupDivRef.clientWidth,
      distDivRef: this.distDivRef.clientWidth
    });
  };

  toggleState = keyId => {
    let stateIndex = this.state.index;
    let nextIndex = ++stateIndex % this.state.states.length;
    this.setState({
      index: nextIndex
    });
    let sortBy = this.state.states[nextIndex];
    this.props.usersSort(keyId, sortBy);
    this.props.getUsersData(
      false,
      this.props.start,
      this.props.limit,
      this.props.searchKey
    );
  };

  renderStates = currentField => {
    const isInSort = this.props.isInSort;
    const states = this.state.states;
    const currentState =
      isInSort === currentField ? states[this.state.index] : 'none';
    if (currentState === 'asc') {
      return (
        <div className={upArrowStyle} key="up-arrow-image">
          <img src={upArrow} alt="" />
        </div>
      );
    } else if (currentState === 'desc') {
      return (
        <div className={downArrowStyle} key="down-arrow-image">
          <img src={downArrow} alt="" />
        </div>
      );
    } else {
      return [
        <div className={upArrowStyle} key="up-arrow-image">
          <img src={upArrow} alt="" />
        </div>,
        <div className={downArrowStyle} key="down-arrow-image">
          <img src={downArrow} alt="" />
        </div>
      ];
    }
  };

  renderHeaders = headerData => {
    return (
      <div className={header}>
        {Object.keys(headerData).map((hName, index) => {
          let width = headerData[hName].width;
          let paddingLeft = headerData[hName].paddingLeft;
          let displaySort = headerData[hName].displaySort;
          return (
            <div
              className={headerCell}
              style={{ width: width, paddingLeft: paddingLeft }}
              key={index}
            >
              {hName}
              <div
                className={sorter}
                onClick={() => this.toggleState(headerData[hName].mappedField)}
              >
                {displaySort === true
                  ? this.renderStates(headerData[hName].mappedField)
                  : null}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  renderUsersData = (usersData, editRowIndex) => {
    if (usersData === undefined) {
      return null;
    }
    const asc = (a, b) => a - b;
    const getDistrictIdsForUser = user => {
      return Object.keys(this.props.distData).length === 0
        ? []
        : this.getDistrictIdsForCurrentUser(user.group_ids).sort(asc);
    };
    return usersData.map((user, index) => {
      let districtIds = getDistrictIdsForUser(user);
      const isEditedRow = editRowIndex !== index;
      return isEditedRow
        ? this.renderRow(index, user, districtIds)
        : this.renderEditedRow(index, user, districtIds);
    });
  };

  getDistrictIdsForCurrentUser = groupIds => {
    const groupDistMap = this.props.distData;
    const districtIds = groupIds
      .map(groupId => {
        return groupDistMap[groupId] || [];
      })
      .flat();
    return Array.from(new Set(districtIds));
  };

  onDeleteClicked(user) {
    this.setState({
      showDeleteModalConfirmation: true,
      selectedUser: user
    });
  }

  onDeleteConfirmed() {
    const userId = this.state.selectedUser.email_id;
    this.props.deleteUser(userId, this.props.start, this.props.limit);
    this.onDismissUserDeletion();
  }

  onDismissUserDeletion() {
    this.setState({
      showDeleteModalConfirmation: false,
      selectedUserId: null
    });
  }

  renderDeleteConfirmation() {
    const showModal = this.state.showDeleteModalConfirmation;
    if (showModal) {
      const userName = this.state.selectedUser.display_name;
      return (
        <DeleteConfirmation
          onConfirmed={this.onDeleteConfirmed}
          onDismissDeleteCancelation={this.onDismissUserDeletion}
          message={getUserDeleteConfirmationMessage(userName)}
          title={'Delete User'}
        />
      );
    }
    return null;
  }

  renderRow = (index, user, districtIds) => {
    const rowHeight = getRowHeightFor(this.props.rowType);
    const dimensions = getPageDimensions(rowHeight, this.props.limit);

    const row = getRowStyleForHeight(dimensions.height);
    const rowCell = getRowCellStyleForHeight(rowHeight);
    const distrowCell = getDistrowCellStyle(rowHeight);

    const renderDistrictsCellsWithId = (cells, style) => {
      return cells.map((id, i) => {
        return (
          <MappleToolTip backgroundColor={'#112138'} key={i}>
            <div className={style}>{id}</div>
            <div className={tootltipStyle}>
              <div className={labelStyle}>Name</div>
              {this.displayDistrictTooltip(id)}
            </div>
          </MappleToolTip>
        );
      });
    };

    const renderGroupsCellsWithId = (cells, style) => {
      return cells.map((id, i) => {
        return (
          <MappleToolTip backgroundColor={'#112138'} key={i}>
            <div className={style}>{id}</div>
            <div className={tootltipStyle}>
              <div className={labelStyle}>Name</div>
              {this.displayGroupTooltip(id)}
            </div>
          </MappleToolTip>
        );
      });
    };

    const renderMoreString = (total, max) => {
      if (total > max) {
        return <div className={more}>{'+ ' + (total - max) + ' more'}</div>;
      }
      return null;
    };

    const groupCellDivWidth = this.state.groupDivWidth;
    const groupIds = user.group_ids;
    const numGroupIds = groupIds.length;
    const groupCellMaxWidth = 90;
    const widthAfterAdjustingPadding = groupCellDivWidth - groupCellMaxWidth;
    const maxGroupsToDisplay =
      widthAfterAdjustingPadding > groupCellMaxWidth
        ? Math.floor(widthAfterAdjustingPadding / groupCellMaxWidth)
        : 1;

    if (districtIds === undefined) {
      districtIds = [];
    }
    const distCellDivWidth = this.state.distDivRef;
    const numDistricts = districtIds.length;
    const unitWidth = 5;
    const init = { total: 0, width: 50 };
    const maxDistrictsToDisplay = districtIds.reduce((acc, cur) => {
      if (acc.width < distCellDivWidth - acc.width) {
        acc.width += cur.toString().length * unitWidth + 10;
        acc.total += 1;
      }
      return acc;
    }, init).total;

    return (
      <div
        className={row}
        key={index}
        id={index}
        onClick={() => this.onRowClicked(index)}
      >
        <div className={rowCell} style={{ paddingLeft: '24px' }}>
          {user['display_name']}
        </div>
        <div className={rowCell} style={{ width: '15%' }}>
          {user['email_id']}
        </div>
        <div
          className={rowCell}
          style={{ width: '25%' }}
          ref={node => (this.groupDivRef = node)}
        >
          {renderGroupsCellsWithId(
            groupIds.slice(0, maxGroupsToDisplay),
            groupCell
          )}
          {renderMoreString(numGroupIds, maxGroupsToDisplay)}
        </div>
        <div className={distrowCell} ref={node => (this.distDivRef = node)}>
          {renderDistrictsCellsWithId(
            districtIds.slice(0, maxDistrictsToDisplay),
            distRowItem
          )}
          {renderMoreString(numDistricts, maxDistrictsToDisplay)}
        </div>
        <div className={rowCell} style={{ width: '8%', paddingRight: '23px' }}>
          <EditButton
            index={index}
            handleEditClick={index => this.onEditButtonClicked(index)}
            editClicked={false}
          />
          <div className={deleteBtn} onClick={() => this.onDeleteClicked(user)}>
            Delete
          </div>
        </div>
      </div>
    );
  };

  displayDistrictTooltip = id => {
    const districts = this.props.distObj;
    if (this.props.distObj === undefined) {
      return null;
    }
    let districtName = '';
    for (let i = 0; i < districts.length; i++) {
      let element = districts[i];
      if (element['id'] === id) {
        districtName = element['name'];
        break;
      }
    }
    return <div className={valueStyle}>{districtName}</div>;
  };

  displayGroupTooltip = id => {
    const groups = this.props.groups.groupsName;
    if (this.props.groups.groupsName === undefined) {
      return null;
    }
    return <div className={valueStyle}>{groups[id]}</div>;
  };

  renderEditedRow = (index, user, districtIds) => {
    let rowHeight = user.group_ids.length / 3 * 43;
    const dimensions = getPageDimensions(rowHeight, this.props.limit);
    const row = getRowStyleForHeight(dimensions.height);
    const rowCell = getEditRowCellStyleForHeight(rowHeight);
    const distrowCell = getDistrowCellStyle(rowHeight);

    const renderDistricts = distIds => {
      if (!distIds || distIds === undefined) {
        return null;
      }
      return districtIds.map((id, index) => {
        return (
          <div key={index} className={distRowItem}>
            {id}
          </div>
        );
      });
    };

    return (
      <div
        className={row}
        style={{ backgroundColor: '#fffae5' }}
        key={index}
        id={index}
      >
        <div className={rowCell} style={{ paddingLeft: '24px' }}>
          <input
            defaultValue={user['display_name']}
            className={inputstyle}
            onChange={this.handleChange.bind(this)}
          />
        </div>
        <div className={rowCell} style={{ width: '15%', alignItems: 'center' }}>
          {user['email_id']}
        </div>
        <div
          className={rowCell}
          style={{ width: '25%', flexFlow: 'wrap', height: 'fit-content' }}
        >
          {user.group_ids.map((groupId, index) => {
            return (
              <div
                key={groupId}
                className={groupCell}
                style={{
                  backgroundColor: '#deebff',
                  justifyContent: 'space-evenly'
                }}
              >
                <div>{groupId}</div>
                <div>
                  <img
                    src={cross}
                    alt=""
                    onClick={() => this.handleCrossButton(index)}
                  />
                </div>
              </div>
            );
          })}
          <div
            className={groupCellEdit}
            onMouseEnter={() => this.handleMouseHover(index)}
            onMouseLeave={() => this.handleMouseHover(index)}
            onClick={this.handleToggeleAddGroups}
          >
            {this.state.isHovering && this.state.hoverIndex === index ? (
              <img src={addIconHover} alt="" />
            ) : (
              <img src={addIcon} alt="" />
            )}
          </div>
        </div>
        <div className={distrowCell}>{renderDistricts(districtIds)}</div>
        <div className={rowCell} style={{ width: '8%', paddingRight: '23px' }}>
          <EditButton
            index={index}
            handleEditClick={index => this.onEditButtonClicked(index)}
            editClicked={true}
          />
          <div className={deleteBtn} onClick={() => this.onDeleteClicked(user)}>
            Delete
          </div>
        </div>
      </div>
    );
  };

  handleClick = e => {
    if (this.containerNode !== null && this.containerNode.contains(e.target)) {
      return;
    }
    this.handleClickOutside();
  };

  handleClickOutside() {
    this.undoEdit();
  }

  undoEdit() {
    this.setState({
      userInput: ''
    });
    this.props.setEditUserRowIndex(-1);
  }

  onRowClicked(index) {
    const isRowInEdit = index === this.editRowIndex;
    if (!isRowInEdit) {
      this.undoEdit();
    }
  }

  onEditButtonClicked = index => {
    const isDoneClicked = index === this.props.editRowIndex && index !== -1;
    const data = this.state.data;
    let displayName = this.props.data[index].display_name;
    let existingGrpIds = this.props.data[index].group_ids;
    const userInput = this.state.userInput;
    let editIndex = -1;
    if (isDoneClicked) {
      let tempData = {
        group_ids: data[index].group_ids,
        email_address: data[index].email_id
      };
      if (userInput.length !== 0) {
        tempData.display_name = userInput;
      }
      //either user name or group id's are changed by user then only do the update api call.
      if (
        (userInput !== '' && userInput !== displayName) ||
        tempData['group_ids'].sort().join(',') !==
          existingGrpIds.sort().join(',')
      ) {
        this.props.updateUserGroupsData(tempData, index);
      }
    } else {
      // Edit clicked
      editIndex = index;
    }
    this.props.setEditUserRowIndex(editIndex);
    this.editRowIndex = editIndex;
  };

  handleMouseHover = index => {
    this.setState({
      isHovering: !this.state.isHovering,
      hoverIndex: index
    });
  };

  handleToggeleAddGroups = () => {
    this.setState({
      showAddGroupsModal: !this.state.showAddGroupsModal
    });
  };

  handleCrossButton = index => {
    let groupIdsCopy = this.state.data[this.props.editRowIndex].group_ids.slice(
      0
    );
    groupIdsCopy.splice(index, 1);
    let tempData = JSON.parse(JSON.stringify(this.state)).data;
    tempData[this.props.editRowIndex].group_ids = groupIdsCopy;
    this.setState({
      data: tempData
    });
  };

  handleChange = event => {
    this.setState({
      userInput: event.target.value
    });
  };

  updateUserGroupsData = (groupIds, index) => {
    let tempData = JSON.parse(JSON.stringify(this.state.data));
    tempData[index].group_ids = groupIds;
    this.setState({
      data: tempData
    });
  };

  showAddGroupsModal = () => {
    return this.state.showAddGroupsModal ? (
      <AddGroupsModal
        updateUserGroupsData={this.updateUserGroupsData}
        groupsData={this.props.groupsData}
        usersData={this.state.data}
        editRowIndex={this.props.editRowIndex}
        toggeleAddGroupsModal={this.handleToggeleAddGroups}
        fetchAllGroups={this.props.fetchAllGroups}
      />
    ) : null;
  };

  render() {
    const rowHeight = getRowHeightFor(this.props.rowType);
    const dimensions = getPageDimensions(rowHeight, this.props.limit);
    const rows = getRowsStyleForHeight(dimensions.height);

    return (
      <div className={container} ref={node => (this.containerNode = node)}>
        <div>{this.renderHeaders(this.state.header)}</div>
        <div>{this.renderDeleteConfirmation()}</div>
        {this.showAddGroupsModal(this.state.data)}
        <div className={rows}>
          {this.renderUsersData(this.state.data, this.props.editRowIndex)}
        </div>
      </div>
    );
  }
}

function getRowStyleForHeight(height) {
  return css({
    display: 'flex',
    '&:nth-of-type(odd)': {
      backgroundColor: '#fafbfc'
    }
  });
}

function getRowsStyleForHeight(height) {
  return css({
    display: 'flex',
    flexDirection: 'column',
    height: `${height}px`,
    overflowY: 'scroll'
  });
}

function getRowCellStyleForHeight(height) {
  return css({
    display: 'flex',
    flexWrap: 'wrap',
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.5',
    letterSpacing: 'normal',
    color: '#112138',
    textAlign: 'left',
    width: '15%',
    height: height,
    alignItems: 'center'
  });
}

function getEditRowCellStyleForHeight(height) {
  return css({
    display: 'flex',
    flexWrap: 'wrap',
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.5',
    letterSpacing: 'normal',
    color: '#112138',
    textAlign: 'left',
    width: '15%',
    margin: '16px 0px 16px 0',
    height: height,
    alignItems: 'flexStart'
  });
}
function getDistrowCellStyle(height) {
  return css({
    display: 'flex',
    fontFamily: 'Open Sans',
    fontSize: '14px',
    flexWrap: 'wrap',
    fontWeight: 600,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.5',
    letterSpacing: 'normal',
    color: '#6c798f',
    textAlign: 'left',
    width: '37%',
    minHeight: height,
    alignItems: 'center'
  });
}

const distRowItem = css({
  borderRadius: '2px',
  border: 'solid 1px #ebecf0',
  backgroundColor: '#ffffff',
  fontFamily: 'Open Sans',
  fontSize: '14px',
  fontWeight: 600,
  fontStyle: 'normal',
  fontStretch: 'normal',
  lineHeight: 1.5,
  letterSpacing: 'normal',
  textAlign: 'center',
  color: '#6c798f',
  margin: '8px 8px 8px 0',
  padding: '5px'
});

const more = css({
  height: '24px',
  fontFamily: 'Open Sans',
  fontSize: '14px',
  fontWeight: 600,
  fontStyle: 'normal',
  fontStretch: 'normal',
  lineHeight: 1.71,
  letterSpacing: 'normal',
  color: '#c1c7d0'
});

const groupCell = css({
  borderRadius: '2px',
  backgroundColor: '#f4f5f7',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  fontFamily: 'Open Sans',
  fontSize: '14px',
  fontWeight: 600,
  fontStyle: 'normal',
  fontStretch: 'normal',
  lineHeight: '1.5',
  letterSpacing: 'normal',
  color: '#6c798f',
  textAlign: 'left',
  width: '84px',
  height: '32px',
  margin: '0px 8px 8px 0',
  alignItems: 'center'
});

const groupCellEdit = css({
  borderRadius: '2px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  fontFamily: 'Open Sans',
  fontSize: '14px',
  fontWeight: 'normal',
  fontStyle: 'normal',
  fontStretch: 'normal',
  lineHeight: '1.5',
  letterSpacing: 'normal',
  color: '#6c798f',
  textAlign: 'left',
  height: '32px',
  alignItems: 'center',
  margin: '0px 8px 8px 0',
  backgroundColor: '#deebff',
  width: '32px',
  '&:hover': {
    backgroundColor: '#0151cb'
  }
});

const container = css({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: '1',
  fontSize: '14px',
  fontWeight: 'normal',
  color: '#112138',
  fontFamily: 'Open Sans',
  lineHeight: 1.5
});

const header = css({
  width: '100%',
  height: '56px',
  backgroundColor: '#f4f5f7',
  display: 'flex'
});

const headerCell = css({
  display: 'flex',
  textTransform: 'uppercase',
  fontFamily: 'Open Sans',
  fontSize: '14px',
  fontWeight: 600,
  fontStyle: 'normal',
  fontStretch: 'normal',
  lineHeight: 1.71,
  letterSpacing: 'normal',
  color: '#5e6c84',
  textAlign: 'left',
  margin: '16px 0px 16px 0',
  userSelect: 'none'
});

const deleteBtn = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  width: '50%',
  fontFamily: 'Open Sans',
  fontSize: '14px',
  fontWeight: 700,
  fontStyle: 'normal',
  fontStretch: 'normal',
  lineHeight: '1.57',
  letterSpacing: 'normal',
  color: '#6c798f',
  cursor: 'pointer',
  textTransform: 'uppercase'
});

const upArrowStyle = css({
  display: 'flex',
  marginBottom: 'auto',
  height: '50%',
  justifyContent: 'center',
  userSelect: 'none'
});

const downArrowStyle = css({
  display: 'flex',
  marginTop: 'auto',
  height: '50%',
  justifyContent: 'center',
  userSelect: 'none'
});

const sorter = css({
  height: '24px',
  width: '18px',
  display: 'flex',
  flexDirection: 'column',
  marginLeft: '15px',
  marginTop: '-2px'
});

const inputstyle = css({
  width: 'auto',
  paddingLeft: '8px',
  height: '32px',
  fontFamily: 'Open Sans',
  fontSize: '14px',
  fontWeight: 'normal',
  fontStyle: 'normal',
  fontStretch: 'normal',
  lineHeight: '1.5',
  letterSpacing: 'normal',
  color: '#112138',
  borderRadius: '2px',
  border: 'solid 1.5px #0151cb'
});

const tootltipStyle = css({
  display: 'flex'
});

const labelStyle = css({
  width: '40px',
  fontFamily: 'Open Sans',
  fontSize: '12px',
  fontWeight: 'normal',
  fontStyle: 'normal',
  fontStretch: 'normal',
  lineHeight: 1.5,
  letterSpacing: 'normal',
  color: '#ffffff'
});

const valueStyle = css({
  paddingLeft: '10px',
  fontFamily: 'Open Sans',
  fontSize: '12px',
  fontWeight: '600',
  fontStyle: 'normal',
  fontStretch: 'normal',
  lineHeight: '1.5',
  letterSpacing: 'normal',
  color: '#ffffff'
});
const mapStateToProps = state => ({
  distData: state.groups.distData,
  distObj: state.districts.data
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setEditUserRowIndex,
      updateUserGroupsData
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(UsersTable);
