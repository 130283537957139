import React, { Component } from 'react';
import { css } from 'emotion';
import tickIcon from '../../assets/white-green-tick.svg';
class Snackbar extends Component {
  render() {
    return (
      <div className={container}>
        <div className={iconStyle}>
          <img src={tickIcon} alt="" />   
        </div>
        <div style={{marginLeft:'16px'}}>
          {this.props.msg}{' '}  
        </div>  
      </div>
    );
  }
}
export default Snackbar;

const container = css({
  display: 'flex',
  alignItems: 'center',
  width: '315px',
  height: '48px',
  fontFamily: 'Open Sans',
  fontSize: '14px',
  fontWeight: 'normal',
  fontStyle: 'normal',
  fontStrech: 'normal',
  lignHeight: 1.5,
  letterSpacing: 'normal',
  color: '#112138',
  borderRadius: '2px',
  boxShadow: '0 10px 10px 0 rgba(0, 0, 0, 0.18)',
  border: 'solid 1px #34b37e',
  backgroundColor: '#ffffff'
});

const iconStyle = css ({
  display: 'flex',
  alignItems: 'center',
  marginLeft:'16px'
})
