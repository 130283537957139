import React, { Component } from 'react';
import { css } from 'emotion';
import Option from '../option/option';
import './styles.css';

export default class AddDistrictssModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tempDistrictsIds: [] // this variable is going to hold temporary data so
      //that upon clicking on cancel button we will not have any impact on old data
    };
    this.node = null;
    this.onOptionSelected = this.onOptionSelected.bind(this);
  }
  distIds = []; //this variable will hold group ids of groups.

  componentDidMount() {
    if(this.distIds !== undefined){
      this.setState({ tempDistrictsIds: this.distIds.slice(0) });
    }
  }

  componentWillMount() {
    document.addEventListener('mousedown', this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
  }

  handleClickOutside = () => {
    this.props.toggleAddDistrictsModal();
  };

  handleClick = e => {
    if (this.node !== null && this.node.contains(e.target)) {
      return;
    }
    this.handleClickOutside();
  };
  onOptionSelected(id, checked) {
    let clickedDistId = this.props.districtsData[id];
    let distIdsCopy;
    if (checked) {
      distIdsCopy = this.state.tempDistrictsIds.slice(0);
      distIdsCopy.push(clickedDistId.id);
    } else {
      distIdsCopy = this.state.tempDistrictsIds.filter(
        value => value !== clickedDistId.id
      );
    }
    this.setState({ tempDistrictsIds: distIdsCopy });
  }
  renderSelectedDistrictIds = () => {
    return this.state.tempDistrictsIds.map((distIds, index) => {
      return (
        <div className={distCell} key={index}>
          {distIds}
        </div>
      );
    });
  };

  renderDistricts = () => {
    var len = this.props.districtsData[0].id.toString().length;
    this.props.districtsData.map(
      v => (len = Math.max(len, v['id'].toString().length))
    );
    return this.props.districtsData.map((dist, index) => {
      return (
        <Option
          Id={dist['id']}
          Name={dist['name']}
          isSelected={this.state.tempDistrictsIds.includes(dist['id'])}
          key={index}
          onSelect={this.onOptionSelected}
          keyId={index}
          idWidth={len}
        />
      );
    });
  };

  handleAddDistricts = () => {
    this.props.updateGroupDistData(
      this.state.tempDistrictsIds,
      this.props.editRowIndex
    );
    this.props.toggleAddDistrictsModal();
  };

  handleCancleBtn = () => {
    this.props.toggleAddDistrictsModal();
  };

  render() {
    this.distIds = this.props.groupsData[this.props.editRowIndex].district_ids;
    if (this.props.districtsData === null) {
      return null;
    }
    return (
      <div className={modalClass}>
        <section className={modalMain} ref={node => (this.node = node)}>
          <div className={container}>
            <span className={title}>Add Districts</span>
          </div>

          <div className={addDistsItemStyle  + ' thin-horiz-scrollbar'}>
            {this.renderSelectedDistrictIds(this.props)}
          </div>
          <div className={container}>
            <span className={title}>All Districts</span>
          </div>
          <div className={distData + ' thin-vert-scrollbar'}>
            {this.renderDistricts(this.props)}
          </div>
          <div className={btn}>
            <div className={cancleBtn} onClick={() => this.handleCancleBtn()}>
              CANCEL
            </div>
            <div
              className={addDistsBtn}
              onClick={() => this.handleAddDistricts()}
            >
              ADD DISTRICTS
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const modalClass = css({
  position: 'absolute',
  zIndex: 1,
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',
  background: 'rgba(0, 0, 0, 0.6)'
});

const modalMain = css({
  position: 'fixed',
  background: 'white',
  width: '364px',
  minHeight: '500px',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%,-50%)',
  borderRadius: '4px',
  boxShadow: '0 10px 10px 0 rgba(0, 0, 0, 0.18)'
});

const container = css({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  height: '48px'
});
const title = css({
  paddingLeft: '16px',
  fontFamily: 'Open Sans',
  fontSize: '16px',
  fontWeight: 600,
  fontStyle: 'normal',
  fontStretch: 'normal',
  lineHeight: 1.5,
  letterSpacing: 'normal',
  color: '#112138'
});
const addDistsItemStyle = css({
  margin: 'auto',
  display: 'flex',
  padding: '5px',
  alignItems: 'center',
  flexDirection: 'row',
  width: '336px',
  height: '48px',
  borderRadius: '2px',
  border: 'solid 1px #dfe1e5',
  backgroundColor: '#ffffff',
  overflowY: 'scroll'
});

const btn = css({
  display: 'flex',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  width: '364px',
  height: '56px',
  borderRadius: '2px',
  backgroundColor: '#fafbfc'
});

const cancleBtn = css({
  width: '50%',
  padding: '16px',
  border: 'solid 1px #ebecf0',
  fontFamily: 'Open Sans',
  fontSize: '14px',
  fontWeight: '700',
  fontStyle: 'normal',
  fontStretch: 'normal',
  lineHeight: 1.5,
  letterSpacing: '1px',
  textAlign: 'center',
  color: '#c1c7d0',
  cursor: 'pointer'
});

const addDistsBtn = css({
  border: 'solid 1px #ebecf0',
  width: '50%',
  padding: '16px',
  fontFamily: 'Open Sans',
  fontSize: '14px',
  fontWeight: '700',
  fontStyle: 'normal',
  fontStretch: 'normal',
  lineHeight: 1.5,
  letterSpacing: '1px',
  textAlign: 'center',
  color: '#0151cb',
  cursor: 'pointer'
});

const distData = css({
  width: '100%',
  height: '300px',
  overflowY: 'scroll'
});

const distCell = css({
  borderRadius: '2px',
  border: 'solid 1px #ebecf0',
  backgroundColor: '#ffffff',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  fontFamily: 'Open Sans',
  fontSize: '14px',
  fontStyle: 'normal',
  fontStretch: 'normal',
  lineHeight: '1.5',
  letterSpacing: 'normal',
  color: '#6c798f',
  textAlign: 'left',
  minWidth: '84px',
  minHeight: '32px',
  alignItems: 'center',
  marginRight: '8px'
});
