import React, { Component } from "react";
import { css } from "emotion";
import refreshIcon from "../../assets/refresh-icon.svg";
import refreshIconHover from "../../assets/refresh-icon-hover.svg";
import upArrow from "../../assets/sort-asc-arrow.svg";
import downArrow from "../../assets/sort-desc-arrow.svg";
import EditButton from "../button/edit-button";
import DeleteConfirmation from "../modal/delete-confirmation";
import AddDistrictsModal from "../modal/add-districts-modal";
import { getGroupDeleteConfirmationMessage } from "../../utils/styles";
import { getRowHeightFor, getPageDimensions } from "../resizer/utils";
import cross from "../../assets/cross.svg";
import addIcon from "../../assets/add-icon.svg";
import addIconHover from "../../assets/add-icon-hover.svg";
import MappleToolTip from "reactjs-mappletooltip";
import { connect } from "react-redux";
import AddAppsModal from "../modal/add-apps-modal";
import AddApisModal from "../modal/add-apis-modal";
import _ from 'lodash';
class GroupsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      states: ["none", "asc", "desc"],
      index: ["none", "asc", "desc"].indexOf(props.sortBy),
      isHovering: false,
      isRefreshHovering: false,
      userInput: "",
      showDeleteModalConfirmation: false,
      selectedGroupId: null,
      data: props.data,
      showDistModal: false,
      showAppModal: false,
      showApiModalL: false,
      header: {
        "GROUP ID": {
          width: "10%",
          paddingLeft: "24px",
          displaySort: true,
          mappedField: "group_id"
        },
        "GROUP NAME": {
          width: "15%",
          displaySort: true,
          mappedField: "group_name"
        },
        APPLICATIONS: {
          width: "15%"
        },
        API: {
          width: "15%"
        },
        "DISTRICT ID": {
          width: "28%"
        },
        "ACCESS CODE": {
          width: "10%"
        },
        ACTIONS: {
          width: "8%"
        }
      }
    };
    this.onDeleteClicked = this.onDeleteClicked.bind(this);
    this.onDeleteConfirmed = this.onDeleteConfirmed.bind(this);
    this.onDismissGroupDeletion = this.onDismissGroupDeletion.bind(this);
    this.distDivRef = null;
    this.containerNode = null;
    this.onRowClicked = this.onRowClicked.bind(this);
    this.editRowIndex = -1;
  }

  componentWillMount() {
    window.addEventListener("resize", this.resizeThrottler, false);
    document.addEventListener("mousedown", this.handleClick, false);
  }

  componentWillReceiveProps(nextProps) {
    const limitHasChanged =
      parseInt(this.props.limit) !== parseInt(nextProps.limit);
    if (limitHasChanged && nextProps.refresh === true) {
      this.props.getGroupsData(
        false,
        this.props.start,
        nextProps.limit,
        nextProps.searchKey
      );
      this.props.shouldRefreshData(false);
    }

    this.setState({
      data: nextProps.data
    });
  }

  componentDidMount() {
    setTimeout(() => {
      this.onResize();
    }, 500);
  }

  resizeThrottler = () => {
    if (!this.resizeTimeout) {
      this.resizeTimeout = setTimeout(() => {
        this.resizeTimeout = null;
        this.onResize();
      }, 66);
    }
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeThrottler, false);
    document.removeEventListener("mousedown", this.handleClick, false);
  }

  onResize = () => {
    const rowHeight = getRowHeightFor(this.props.rowType);
    const dimensions = getPageDimensions(rowHeight);
    const newLimit = dimensions.noOfRows;
    this.props.changeDataLimit(newLimit, this.props.totalRecords);
    this.props.getGroupsData(
      false,
      this.props.start,
      newLimit,
      this.props.searchKey
    );
    this.setState({
      rowsHeight: dimensions.remainingHeight,
      distCellDivWidth: this.distDivRef.clientWidth
    });
  };

  toggleState = keyId => {
    let stateIndex = this.state.index;
    let nextIndex = ++stateIndex % this.state.states.length;
    this.setState({
      index: nextIndex
    });
    let sortBy = this.state.states[nextIndex];
    this.props.groupsSort(keyId, sortBy);
    this.props.getGroupsData(
      false,
      this.props.start,
      this.props.limit,
      this.props.searchKey
    );
  };

  renderStates = currentField => {
    const isInSort = this.props.isInSort;
    const states = this.state.states;
    const currentState =
      isInSort === currentField ? states[this.state.index] : "none";
    if (currentState === "asc") {
      return (
        <div className={upArrowStyle} key="up-arrow-image">
          <img src={upArrow} alt="" />
        </div>
      );
    } else if (currentState === "desc") {
      return (
        <div className={downArrowStyle} key="down-arrow-image">
          <img src={downArrow} alt="" />
        </div>
      );
    } else {
      return [
        <div className={upArrowStyle} key="up-arrow-image">
          <img src={upArrow} alt="" />
        </div>,
        <div className={downArrowStyle} key="down-arrow-image">
          <img src={downArrow} alt="" />
        </div>
      ];
    }
  };

  renderHeaders = headerData => {
    return (
      <div className={header}>
        {Object.keys(headerData).map((hName, index) => {
          let width = headerData[hName].width;
          let paddingLeft = headerData[hName].paddingLeft;
          let displaySort = headerData[hName].displaySort;

          return (
            <div
              className={headerCell}
              style={{ width: width, paddingLeft: paddingLeft }}
              key={index}
            >
              {hName}
              <div
                className={sorter}
                onClick={() => this.toggleState(headerData[hName].mappedField)}
              >
                {displaySort === true
                  ? this.renderStates(headerData[hName].mappedField)
                  : null}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  handleClicked = index => {
    const isDoneClicked = index === this.props.editRowIndex && index !== -1;
    const data = this.state.data;
    const userInput = this.state.userInput;
    let existingGrpName = this.props.data[index].name;
    let existingDistIds = this.props.data[index].district_ids;
    let existingApps = this.props.data[index].apps;
    let existingApis = this.props.data[index].apis;
    let editIndex = -1;
    if (isDoneClicked) {
      let tempData = {
        groups: [data[index].id],
        district_ids: data[index].district_ids,
        apps: data[index].apps,
        apis: data[index].apis,
        op: "update"
      };
      if (userInput.length !== 0) {
        tempData.name = userInput;
      }
      //either group name or district id's are changed by user then only do the update api call.
      if (
        (userInput !== "" && userInput !== existingGrpName) ||
        tempData["district_ids"].sort().join(",") !==
          existingDistIds.sort().join(",") ||
        tempData["apps"].sort().join(",") !== existingApps.sort().join(",") ||
        !_.isEqual(tempData['apis'], existingApis)
      ) {
        this.props.updateGroupData(tempData, index);
      }
    } else {
      // Edit clicked
      editIndex = index;
    }
    this.props.setEditGroupRowIndex(editIndex);
    this.editRowIndex = editIndex;
  };

  handleMouseHover = index => {
    this.setState({
      isHovering: !this.state.isHovering,
      hoverIndex: index
    });
  };

  handleRefreshHover = index => {
    this.setState({
      isRefreshHovering: !this.state.isRefreshHovering,
      hoverIndex: index
    });
  };

  toggleAddDistrictsModal = () => {
    this.setState({
      showDistModal: !this.state.showDistModal
    });
  };

  toggleAddAppsModal = () => {
    this.setState({
      showAppModal: !this.state.showAppModal
    });
  };
  toggleAddApisModal = () => {
    this.setState({
      showApiModal: !this.state.showApiModal
    });
  };
  handleCrossButton = index => {
    let distIdsCopy = this.state.data[
      this.props.editRowIndex
    ].district_ids.slice(0);
    distIdsCopy.splice(index, 1);

    let tempData = JSON.parse(JSON.stringify(this.state)).data;
    tempData[this.props.editRowIndex].district_ids = distIdsCopy;
    this.setState({
      data: tempData
    });
  };

  handleCrossButtonforApp = index => {
    let appsCopy = this.state.data[this.props.editRowIndex].apps.slice(0);
    appsCopy.splice(index, 1);

    let tempData = JSON.parse(JSON.stringify(this.state)).data;
    tempData[this.props.editRowIndex].apps = appsCopy;
    this.setState({
      data: tempData
    });
  };

  handleCrossButtonforApi = index => {
    let apisCopy = this.state.data[this.props.editRowIndex].apis.slice(0);
    apisCopy.splice(index, 1);

    let tempData = JSON.parse(JSON.stringify(this.state)).data;
    tempData[this.props.editRowIndex].apis = apisCopy;
    this.setState({
      data: tempData
    });
  };
  handleChange = event => {
    this.setState({
      userInput: event.target.value
    });
  };

  updateGroupDistData = (districtIds, index) => {
    let tempData = JSON.parse(JSON.stringify(this.state.data));
    tempData[index].district_ids = districtIds;
    this.setState({
      data: tempData
    });
  };

  updateGroupAppData = (apps, index) => {
    let tempData = JSON.parse(JSON.stringify(this.state.data));
    tempData[index].apps = apps;
    this.setState({
      data: tempData
    });
  };

  updateGroupApiData = (apis, index) => {
    let tempData = JSON.parse(JSON.stringify(this.state.data));
    tempData[index].apis = apis;
    this.setState({
      data: tempData
    });
  };
  renderGroupsData = (
    groupsData,
    row,
    rowCell,
    appCell,
    distrowCell,
    accessCodeCell,
    editRowIndex
  ) => {
    return groupsData.map((group, index) => {
      const isEditedRow = editRowIndex !== index;
      return isEditedRow
        ? this.renderRow(
            index,
            group,
            row,
            rowCell,
            appCell,
            distrowCell,
            accessCodeCell
          )
        : this.renderEditedRow(
            index,
            group,
            row,
            rowCell,
            distrowCell,
            accessCodeCell
          );
    });
  };

  onDeleteClicked(group) {
    this.setState({
      showDeleteModalConfirmation: true,
      selectedGroup: group
    });
  }

  onDeleteConfirmed() {
    const groupId = this.state.selectedGroup.id;
    this.props.deleteGroup(groupId, this.props.start, this.props.limit);
    this.onDismissGroupDeletion();
  }

  onDismissGroupDeletion() {
    this.setState({
      showDeleteModalConfirmation: false,
      selectedGroupId: null
    });
  }

  renderDeleteConfirmation() {
    const showModal = this.state.showDeleteModalConfirmation;
    if (showModal) {
      const groupName = this.state.selectedGroup.name;

      return (
        <DeleteConfirmation
          onConfirmed={this.onDeleteConfirmed}
          onDismissDeleteCancelation={this.onDismissGroupDeletion}
          message={getGroupDeleteConfirmationMessage(groupName)}
          title={"Delete Group"}
        />
      );
    }
    return null;
  }

  showAddDistrictsModal = () => {
    return this.state.showDistModal ? (
      <AddDistrictsModal
        updateGroupDistData={this.updateGroupDistData}
        districtsData={this.props.districtsData}
        groupsData={this.state.data}
        editRowIndex={this.props.editRowIndex}
        toggleAddDistrictsModal={this.toggleAddDistrictsModal}
      />
    ) : null;
  };

  showAddAppsModal = () => {
    return this.state.showAppModal ? (
      <AddAppsModal
        updateGroupAppData={this.updateGroupAppData}
        appsData={this.props.appsData}
        groupsData={this.state.data}
        editRowIndex={this.props.editRowIndex}
        toggleAddAppsModal={this.toggleAddAppsModal}
      />
    ) : null;
  };

  showAddApisModal = () => {
    return this.state.showApiModal ? (
      <AddApisModal
        updateGroupApiData={this.updateGroupApiData}
        apisData={this.props.apisData}
        groupsData={this.state.data}
        editRowIndex={this.props.editRowIndex}
        toggleAddApisModal={this.toggleAddApisModal}
      />
    ) : null;
  };

  displayDistrictTooltip = id => {
    const districts = this.props.distObj;
    if (this.props.distObj === undefined) {
      return null;
    }
    let districtName = "";
    for (let i = 0; i < districts.length; i++) {
      let element = districts[i];
      if (element["id"] === id) {
        districtName = element["name"];
        break;
      }
    }
    return <div className={valueStyle}>{districtName}</div>;
  };

  renderRow = (
    index,
    group,
    row,
    rowCell,
    appCell,
    distrowCell,
    accessCodeCell
  ) => {
    const renderCellsWithId = (cells, style) => {
      return cells.map((id, index) => {
        return (
          <MappleToolTip backgroundColor={"#112138"} key={index}>
            <div className={style} style={{justifyContent:'center'}}>{id}</div>
            <div className={tootltipStyle}>
              <div className={labelStyle}>Name</div>
              {this.displayDistrictTooltip(id)}
            </div>
          </MappleToolTip>
        );
      });
    };

    const renderMoreString = (total, max) => {
      if (total > max) {
        return <div className={more}>{"+ " + (total - max) + " more"}</div>;
      }
      return null;
    };

    const distCellDivWidth = this.state.distCellDivWidth;
    let distIds = group.district_ids;
    let apps = group.apps;
    let apis = [];
    if (group.apis !== undefined) {
      group.apis.forEach(apiobj => {
        apis.push(apiobj.method);
      });
    }
    if (distIds === undefined) {
      distIds = [];
    }
    const numDistIds = distIds.length;
    const distCellMaxWidth = 90;
    const widthAfterAdjustingPadding = distCellDivWidth - distCellMaxWidth;
    const maxDistsToDisplay =
      widthAfterAdjustingPadding > distCellMaxWidth
        ? Math.floor(widthAfterAdjustingPadding / distCellMaxWidth)
        : 1;

    return (
      <div className={row} key={index} onClick={() => this.onRowClicked(index)}>
        <div className={rowCell} style={{ paddingLeft: "24px" }}>
          <div className={groupCell}>{group["id"]}</div>
        </div>
        <div className={rowCell} style={{ width: "15%" }}>
          {group["name"]}
        </div>
        <div className={rowCell} style={{ width: "15%" }}>
          <div className={appCell}>{apps !== undefined ? apps.join(", ") : ""}</div>
        </div>

        <div className={rowCell} style={{ width: "15%" }}>
          <div className={appCell}>
            {apis !== undefined ? apis.join(", ") : ""}
          </div>
        </div>
        <div className={distrowCell} ref={node => (this.distDivRef = node)}>
          {renderCellsWithId(distIds.slice(0, maxDistsToDisplay), distRowItem)}
          {renderMoreString(numDistIds, maxDistsToDisplay)}
        </div>
        <div
          className={accessCodeCell}
          onMouseEnter={() => this.handleRefreshHover(index)}
          onMouseLeave={() => this.handleRefreshHover(index)}
        >
          {group["access_code"]}
          {this.state.isRefreshHovering && this.state.hoverIndex === index ? (
            <img
              src={refreshIconHover}
              alt=""
              onClick={() => this.props.refreshAccessCode(group.id)}
              style={{ paddingRight: "25%" }}
            />
          ) : (
            <img src={refreshIcon} alt="" style={{ paddingRight: "25%" }} />
          )}
        </div>
        <div className={rowCell} style={{ width: "8%", paddingRight: "23px" }}>
          <EditButton
            index={index}
            handleEditClick={index => this.handleClicked(index)}
            editClicked={false}
          />
          <div
            className={deleteBtn}
            onClick={() => this.onDeleteClicked(group)}
          >
            Delete
          </div>
        </div>
      </div>
    );
  };

  renderEditedRow = (
    index,
    group,
    row,
    rowCell,
    distrowCell,
    accessCodeCell
  ) => {
    let apis = [];
    if (group.apis !== undefined) {
      group.apis.forEach(obj => {
        apis.push(obj.method);
      });
    }
    return (
      <div className={row} style={{ backgroundColor: "#fffae5" }} key={index}>
        <div className={rowCell} style={{ paddingLeft: "24px" }}>
          <div className={groupCell}>{group["id"]}</div>
        </div>
        <div className={rowCell} style={{ width: "15%" }}>
          <input
            defaultValue={group["name"]}
            className={inputstyle}
            onChange={this.handleChange.bind(this)}
          />
        </div>
        <div className={distrowCell} style={{ width: "15%" }}>
          { group.apps && group.apps.map((app, index) => {
            return (
              <div
                key={index}
                className={distRowItem}
                style={{
                  backgroundColor: "#deebff",
                  position:'relative'
                }}
              >
                <div className={listItem}>{app}</div>
                  <div style={{position:'absolute', right:'8px'}}>
                  <img
                    src={cross}
                    alt=""
                    onClick={() => this.handleCrossButtonforApp(index)}
                  />
                </div>
              </div>
            );
          })}
          <div
            className={distCellEdit}
            onMouseEnter={() => this.handleMouseHover(index)}
            onMouseLeave={() => this.handleMouseHover(index)}
            onClick={this.toggleAddAppsModal}
          >
            {this.state.isHovering && this.state.hoverIndex === index ? (
              <img src={addIconHover} alt="" style={{ height: "fixed" }} />
            ) : (
              <img src={addIcon} alt="" style={{ height: "fixed" }} />
            )}
          </div>
        </div>
        <div className={distrowCell} style={{ width: "15%", height: "100%" }}>
          {apis.map((api, index) => {
            return (
              <div
                key={index}
                className={distRowItem}
                style={{
                  backgroundColor: "#deebff",
                  position:'relative'
                }}
              >
                <div className={listItem}>{api}</div>
                <div style={{position:'absolute', right:'8px'}}>
                  <img
                    src={cross}
                    alt=""
                    onClick={() => this.handleCrossButtonforApi(index)}
                  />
                </div>
              </div>
            );
          })}
          <div
            className={distCellEdit}
            onMouseEnter={() => this.handleMouseHover(index)}
            onMouseLeave={() => this.handleMouseHover(index)}
            onClick={this.toggleAddApisModal}
          >
            {this.state.isHovering && this.state.hoverIndex === index ? (
              <img
                src={addIconHover}
                alt="-"
                style={{ height: "fixed !important" }}
              />
            ) : (
              <img
                src={addIcon}
                alt="-"
                style={{ height: "fixed !important" }}
              />
            )}
          </div>
        </div>
        <div className={distrowCell}>
          {group.district_ids.map((distId, index) => {
            return (
              <div
                key={distId}
                className={distRowItem}
                style={{
                  backgroundColor: "#deebff",
                  justifyContent: "space-evenly"
                }}
              >
                {distId}
                <div>
                  <img
                    src={cross}
                    alt=""
                    onClick={() => this.handleCrossButton(index)}
                  />
                </div>
              </div>
            );
          })}
          <div
            className={distCellEdit}
            onMouseEnter={() => this.handleMouseHover(index)}
            onMouseLeave={() => this.handleMouseHover(index)}
            onClick={this.toggleAddDistrictsModal}
          >
            {this.state.isHovering && this.state.hoverIndex === index ? (
              <img src={addIconHover} alt="" style={{ height: "fixed" }} />
            ) : (
              <img src={addIcon} alt="" style={{ height: "fixed" }} />
            )}
          </div>
        </div>
        <div
          className={accessCodeCell}
          onMouseEnter={() => this.handleRefreshHover(index)}
          onMouseLeave={() => this.handleRefreshHover(index)}
        >
          {group["access_code"]}
          {this.state.isRefreshHovering && this.state.hoverIndex === index ? (
            <img
              src={refreshIconHover}
              alt=""
              onClick={() => this.props.refreshAccessCode(group.id)}
              style={{ paddingRight: "25%" }}
            />
          ) : (
            <img src={refreshIcon} alt="" style={{ paddingRight: "25%" }} />
          )}
        </div>
        <div className={rowCell} style={{ width: "8%", paddingRight: "23px" }}>
          <EditButton
            index={index}
            handleEditClick={index => this.handleClicked(index)}
            editClicked={true}
          />
          <div
            className={deleteBtn}
            onClick={() => this.onDeleteClicked(group)}
          >
            Delete
          </div>
        </div>
      </div>
    );
  };

  handleClick = e => {
    if (this.containerNode !== null && this.containerNode.contains(e.target)) {
      return;
    }
    this.handleClickOutside();
  };

  handleClickOutside() {
    this.undoEdit();
  }

  undoEdit() {
    this.setState({
      userInput: ""
    });
    this.props.setEditGroupRowIndex(-1);
  }

  onRowClicked(index) {
    const isRowInEdit = index === this.editRowIndex;
    if (!isRowInEdit) {
      this.undoEdit();
    }
  }

  render() {
    const rowHeight = getRowHeightFor(this.props.rowType);
    const dimensions = getPageDimensions(rowHeight, this.props.limit);
    const rows = getRowsStyle(dimensions.height);
    const row = getRowStyle(dimensions.height);
    const rowCell = getRowCellStyle(rowHeight);
    const appCell = getAppCellStyle(rowHeight);
    const accessCodeCell = getAccessCodeCellStyle(rowHeight);
    const distrowCell = getDistrowCellStyle(rowHeight);

    return (
      <div className={container} ref={node => (this.containerNode = node)}>
        <div>{this.renderHeaders(this.state.header)}</div>
        <div>{this.renderDeleteConfirmation()}</div>
        {this.showAddDistrictsModal(this.state.data)}
        {this.showAddAppsModal(this.state.data)}
        {this.showAddApisModal(this.state.data)}
        <div className={rows}>
          {this.renderGroupsData(
            this.state.data,
            row,
            rowCell,
            appCell,
            distrowCell,
            accessCodeCell,
            this.props.editRowIndex
          )}
        </div>
      </div>
    );
  }
}

function getRowsStyle(height) {
  return css({
    display: "flex",
    flexDirection: "column",
    height: `${height}px`,
    overflowY: "scroll"
  });
}

function getRowStyle(height) {
  return css({
    display: "flex",
    // height: `${height}px`,
    "&:nth-of-type(odd)": {
      backgroundColor: "#fafbfc"
    }
  });
}

function getRowCellStyle(height) {
  return css({
    display: "flex",
    fontFamily: "Open Sans",
    fontSize: "14px",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "1.5",
    letterSpacing: "normal",
    color: "#112138",
    textAlign: "left",
    width: "10%",
    alignItems: "center",
    height: height
  });
}
function getAppCellStyle(height) {
  return css({
    paddingRight: "20px",
    wordBreak: "break-word",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  });
}

function getAccessCodeCellStyle(height) {
  return css({
    display: "flex",
    justifyContent: "space-between",
    width: "10%",
    fontFamily: "Open Sans",
    fontSize: "14px",
    fontWeight: 600,
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "1.5",
    letterSpacing: "normal",
    color: "#112138",
    textAlign: "left",
    alignItems: "center",
    height: height
  });
}

function getDistrowCellStyle(height) {
  return css({
    display: "flex",
    flexWrap: "wrap",
    fontFamily: "Open Sans",
    fontSize: "14px",
    fontWeight: 600,
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "1.5",
    letterSpacing: "normal",
    color: "#6c798f",
    textAlign: "left",
    width: "28%",
    height: height,
    alignItems: "center"
  });
}

const distRowItem = css({
  borderRadius: "2px",
  border: "solid 1px #ebecf0",
  backgroundColor: "#ffffff",
  display: "flex",
  flexDirection: "row",
  justifyContent: "start",
  fontFamily: "Open Sans",
  fontSize: "14px",
  fontWeight: "normal",
  fontStyle: "normal",
  fontStretch: "normal",
  lineHeight: "1.5",
  letterSpacing: "normal",
  olor: "#6c798f",
  textAlign: "left",
  width: "84px",
  height: "32px",
  margin: "8px 8px 8px 0",
  alignItems: "center",
  whiteSpace: "nowrap",
  overflow: 'hidden',
  wordBreak: 'break-word'
});

const listItem = css({
  whiteSpace: "nowrap",
  overflow: 'hidden',
  wordBreak: 'break-word',
  width:'55px',
  paddingLeft: '5px'
})

const inputstyle = css({
  width: "auto",
  paddingLeft: "8px",
  height: "32px",
  fontFamily: "Open Sans",
  fontSize: "14px",
  fontWeight: "normal",
  fontStyle: "normal",
  fontStretch: "normal",
  lineHeight: "1.5",
  letterSpacing: "normal",
  color: "#112138",
  borderRadius: "2px",
  border: "solid 1.5px #0151cb"
});

const more = css({
  height: "24px",
  fontFamily: "Open Sans",
  fontSize: "14px",
  fontWeight: 600,
  fontStyle: "normal",
  fontStretch: "normal",
  lineHeight: 1.71,
  letterSpacing: "normal",
  color: "#c1c7d0"
});

const groupCell = css({
  borderRadius: "2px",
  backgroundColor: "#f4f5f7",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  fontFamily: "Open Sans",
  fontSize: "14px",
  fontWeight: 600,
  fontStyle: "normal",
  fontStretch: "normal",
  lineHeight: "1.5",
  letterSpacing: "normal",
  color: "#6c798f",
  textAlign: "left",
  width: "84px",
  height: "32px",
  alignItems: "center"
});

const distCellEdit = css({
  borderRadius: "2px",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  fontFamily: "Open Sans",
  fontSize: "14px",
  fontWeight: "normal",
  fontStyle: "normal",
  fontStretch: "normal",
  lineHeight: "1.5",
  letterSpacing: "normal",
  color: "#112138",
  textAlign: "left",
  height: "32px",
  alignItems: "center",
  margin: "8px 8px 8px 0",
  backgroundColor: "#deebff",
  width: "32px",
  "&:hover": {
    backgroundColor: "#0151cb"
  }
});
const container = css({
  display: "flex",
  flexDirection: "column",
  flexGrow: "1",
  fontSize: "14px",
  fontWeight: "normal",
  color: "#112138",
  fontFamily: "Open Sans",
  lineHeight: 1.5,
  maxWidth: "100%"
});

const header = css({
  width: "100%",
  height: "56px",
  backgroundColor: "#f4f5f7",
  display: "flex"
});

const headerCell = css({
  display: "flex",
  textTransform: "uppercase",
  fontFamily: "Open Sans",
  fontSize: "14px",
  fontWeight: 600,
  fontStyle: "normal",
  fontStretch: "normal",
  lineHeight: 1.71,
  letterSpacing: "normal",
  color: "#5e6c84",
  textAlign: "left",
  width: "20%",
  padding: "16px 0 16px 0",
  userSelect: "none"
});

const deleteBtn = css({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  width: "50%",
  fontFamily: "Open Sans",
  fontSize: "14px",
  fontWeight: "bold",
  fontStyle: "normal",
  fontStretch: "normal",
  lineHeight: "1.57",
  letterSpacing: "normal",
  color: "#6c798f",
  cursor: "pointer",
  textTransform: "uppercase"
});

const upArrowStyle = css({
  display: "flex",
  marginBottom: "auto",
  height: "50%",
  justifyContent: "center",
  userSelect: "none"
});

const downArrowStyle = css({
  display: "flex",
  marginTop: "auto",
  height: "50%",
  justifyContent: "center",
  userSelect: "none"
});

const sorter = css({
  height: "24px",
  width: "18px",
  display: "flex",
  flexDirection: "column",
  marginLeft: "15px",
  marginTop: "-2px"
});

const tootltipStyle = css({
  display: "flex"
});

const labelStyle = css({
  width: "40px",
  fontFamily: "Open Sans",
  fontSize: "12px",
  fontWeight: "normal",
  fontStyle: "normal",
  fontStretch: "normal",
  lineHeight: 1.5,
  letterSpacing: "normal",
  color: "#ffffff"
});

const valueStyle = css({
  paddingLeft: "10px",
  fontFamily: "Open Sans",
  fontSize: "12px",
  fontWeight: "600",
  fontStyle: "normal",
  fontStretch: "normal",
  lineHeight: "1.5",
  letterSpacing: "normal",
  color: "#ffffff"
});

const mapStateToProps = state => ({
  distObj: state.districts.data
});
export default connect(mapStateToProps, null)(GroupsTable);
