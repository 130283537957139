import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { connectedReduxRedirect } from 'redux-auth-wrapper/history3/redirect';

import UsersPageComponent from '../pages/users';
import PageNotFound from '../pages/page-not-found';
import LoginContainer from '../login/logincontainer';
import { withRouter } from 'react-router';
import AppHeader from '../app-header/app-header';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import { logAppVersion } from '../../modules/app';
import { logout } from '../../modules/user';
import { shouldRefreshToken } from '../../utils/user';
import { version } from '../../../package.json';

import './app.css';

const userIsAuthenticated = connectedReduxRedirect({
  redirectPath: '/',
  authenticatedSelector: state =>
    typeof state.user.isLoggedIn !== 'undefined' &&
    state.user.isLoggedIn !== false &&
    !shouldRefreshToken(state.user.expiresAt),
  wrapperDisplayName: 'UserIsAuthenticated',
  // This should be a redux action creator
  redirectAction: path => () => {
    window.location.href = '/login';
  }
});

const UsersPage = userIsAuthenticated(UsersPageComponent);

class App extends Component {
  componentWillMount() {
    this.props.logAppVersion(version);
  }

  render() {
    return (
      <div>
        <header>
          <AppHeader
            logoutAction={this.props.logout}
            goToLoginPage={this.props.goToLoginPage}
            user={this.props.user}
          />
        </header>
        <main>
          <Switch>
            <Route exact path="/" component={UsersPage} />
            <Route exact path="/login" component={LoginContainer} />
            <Route path="*" exact={true} component={PageNotFound} />
          </Switch>
        </main>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
  app: state.app
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      logout,
      logAppVersion,
      goToLoginPage: () => push('login')
    },
    dispatch
  );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
