import { logout } from "./user.js";
import persistor from "../index";
import { REHYDRATE } from "redux-persist/constants";
import { toggleNotification } from "./notification";
import { API_ROOT } from '../components/constants/api';
import { isTokenValid } from '../utils/user';

export const ALL_DISTRICTS_DATA_RECIEVED = "ALL_DISTRICTS_DATA_RECIEVED";
export const ALL_DISTRICT_DATA_REQUESTED = "ALL_DISTRICT_DATA_REQUESTED";
export const SET_SELECTED_DISTRICT_ID = "SET_SELECTED_DISTRICT_ID";
const initialState = {
  data: null,
  selectedIds: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case REHYDRATE:
      persistor.purge();
      return state;
    case ALL_DISTRICTS_DATA_RECIEVED:
      let distPayload = action.payload;
      return {
        ...state,
        data: distPayload.districts,
        status: ALL_DISTRICTS_DATA_RECIEVED
      };
    case ALL_DISTRICT_DATA_REQUESTED:
      return{
        ...state,
        status: ALL_DISTRICT_DATA_REQUESTED
      }
    case SET_SELECTED_DISTRICT_ID:
      return{
        ...state,
        selectedIds: action.payload.tempIds
      }
    default:
      return state;
  }
};

export const getSelectedDistrictIds = (tempIds) =>{
  return dispatch => {
    dispatch({
      type: SET_SELECTED_DISTRICT_ID,
      payload: {
        tempIds: tempIds
      }
    });
  };
}
export const getDistrictsData = (districts = []) => {
  return (dispatch, getState) => {
    dispatch({
      type: ALL_DISTRICT_DATA_REQUESTED
    });
    const tokenExpiry = getState().user.expiresAt;
    if (!isTokenValid(tokenExpiry)) {
      dispatch(logout());
    }
    const distsUrl = API_ROOT + 'districts';
    const token = getState().user.token;
    const data = {
      'districts': districts
    };
    const options = {
      method: 'POST',
      headers: {
        Xauthtoken: token,
        'Content-type': 'application/json'
      },
      body: JSON.stringify(data)
    };
    const message = 'We could not find district detail at this moment.';
    fetch(distsUrl, options)
      .then(response => {
        if (response.status >= 400) {
          if (response.status === 403) {
            dispatch(logout(false));
          }
          dispatch(toggleNotification(message));
          return null;
        }
        return response.json();
      })
      .then(json => {
        if (json !== null) {
          dispatch({
            type: ALL_DISTRICTS_DATA_RECIEVED,
            payload: {
              districts: json.districts
            }
          });
        }
      });
  };
}
