import { logout } from './user.js';
import persistor from '../index';
import { REHYDRATE } from 'redux-persist/constants';
import { toggleNotification } from './notification';
import { API_ROOT } from '../components/constants/api';
import { isTokenValid } from '../utils/user';
import { receivedPaginationData } from './pagination';
export const GROUPS_DATA_REQUESTED = 'GROUPS_DATA_REQUESTED';
export const GROUPS_DATA_ERROR = 'GROUPS_DATA_ERROR';
export const GROUPS_DATA_RECIEVED = 'GROUPS_DATA_RECIEVED';
export const RESET_GROUPS_DATA = 'RESET_GROUPS_DATA';
export const GROUPS_SORT = 'GROUPS_SORT';
export const SET_GROUP_ROW_INDEX = 'SET_GROUP_ROW_INDEX';
export const DISTRICT_DATA_REQUESTED = 'DISTRICT_DATA_REQUESTED';
export const DISTRICT_DATA_RECIEVED = 'DISTRICT_DATA_RECIEVED';
export const UPDATE_GROUPS_DATA_REQUESTED = 'UPDATE_GROUPS_DATA_REQUESTED';
export const UPDATE_GROUPS_DATA_RECIEVED = 'UPDATE_GROUPS_DATA_RECIEVED';
export const DELETE_GROUP_REQUESTED = 'DELETE_GROUP_REQUESTED';
export const DELETE_GROUP_RECIEVED = 'DELETE_GROUP_RECIEVED';
export const REFRESH_ACCESS_CODE_REQUESTED = 'REFRESH_ACCESS_CODE_REQUESTED';
export const REFRESH_ACCESS_CODE_RECIEVED = 'REFRESH_ACCESS_CODE_RECIEVED';
export const CREATE_GROUP_REQUESTED = 'CREATE_GROUP_REQUESTED';
export const NEW_GROUP_RECIEVED = 'NEW_GROUP_RECIEVED';
export const TOGGLE_NOTIFICATION_FOR_GROUPS = 'TOGGLE_NOTIFICATION_FOR_GROUPS';
export const All_GROUPS_DATA_REQUESTED = 'All_GROUPS_DATA_REQUESTED';
export const All_GROUPS_DATA_ERROR = 'All_GROUPS_DATA_ERROR';
export const All_GROUPS_DATA_RECIEVED = 'All_GROUPS_DATA_RECIEVED';
export const ACCESS_DENIED_MESSAGE =
  'You are not authorized to perform this operation.';
const initialState = {
  data: null,
  distData: {},
  displayGroups: false,
  sortBy: 'none',
  groupsColumnInSort: null,
  editRowIndex: -1,
  totalRecords: 0,
  isNotify: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case REHYDRATE:
      persistor.purge();
      return state;

    case GROUPS_DATA_REQUESTED:
      return {
        ...state,
        status: GROUPS_DATA_REQUESTED
      };
    case GROUPS_DATA_ERROR:
      return {
        ...state,
        status: GROUPS_DATA_ERROR
      };
    case SET_GROUP_ROW_INDEX:
      return {
        ...state,
        editRowIndex: action.payload.index
      };
    case GROUPS_DATA_RECIEVED:
      let payload = action.payload;
      return {
        ...state,
        data: payload.groups,
        status: GROUPS_DATA_RECIEVED,
        displayGroups: true,
        totalRecords: payload.totalRecords
      };
    case RESET_GROUPS_DATA:
      return {
        ...state,
        data: null,
        displayGroups: false
      };
    case DELETE_GROUP_REQUESTED:
      return {
        ...state,
        status: DELETE_GROUP_REQUESTED
      };
    case DELETE_GROUP_RECIEVED:
      const id = action.payload.id;
      return deleteGroup(state, id);
    case REFRESH_ACCESS_CODE_REQUESTED:
      return {
        ...state,
        status: REFRESH_ACCESS_CODE_REQUESTED
      };
    case REFRESH_ACCESS_CODE_RECIEVED:
      let newData = state.data.map(group => {
        if (group.id === action.payload.groupId) {
          group.access_code = action.payload.json.access_code;
        }
        return group;
      });
      return {
        ...state,
        data: newData
      };
    case GROUPS_SORT:
      const order = action.payload.order;
      const key = action.payload.key;
      return performSort(state, order, key);
    case UPDATE_GROUPS_DATA_REQUESTED:
      return {
        ...state,
        status: UPDATE_GROUPS_DATA_REQUESTED
      };
    case UPDATE_GROUPS_DATA_RECIEVED:
      let index = action.payload.index;
      let tempdata = state.data.slice(0);
      tempdata[index].district_ids = action.payload.data.district_ids;
      tempdata[index].apps = action.payload.data.apps;
      tempdata[index].apis = action.payload.data.apis;
      if (action.payload.data.name) {
        tempdata[index].name = action.payload.data.name;
      }
      return {
        ...state,
        data: tempdata,
        status: UPDATE_GROUPS_DATA_RECIEVED
      };
    case DISTRICT_DATA_RECIEVED:
      return {
        ...state,
        distData: action.payload.data
      };
    case CREATE_GROUP_REQUESTED:
      return {
        ...state,
        status: CREATE_GROUP_REQUESTED
      };
    case NEW_GROUP_RECIEVED:
      return {
        ...state,
        status: NEW_GROUP_RECIEVED
      };
    case TOGGLE_NOTIFICATION_FOR_GROUPS:
      return {
        ...state,
        isNotify: !state.isNotify
      };
    case All_GROUPS_DATA_REQUESTED:
      return {
        ...state,
        status: All_GROUPS_DATA_REQUESTED
      };
    case All_GROUPS_DATA_ERROR:
      return {
        ...state,
        status: All_GROUPS_DATA_ERROR
      };
    case All_GROUPS_DATA_RECIEVED:
      return {
        ...state,
        groupsName: action.payload.data
      };
    default:
      return state;
  }
};

export function setEditGroupRowIndex(index) {
  return dispatch => {
    dispatch({
      type: SET_GROUP_ROW_INDEX,
      payload: {
        index: index
      }
    });
  };
}

export const refreshAccessCode = groupId => {
  return (dispatch, getState) => {
    dispatch({
      type: REFRESH_ACCESS_CODE_REQUESTED
    });
    const creds = getState().user;
    const tokenExpiry = creds.expiresAt;
    if (!isTokenValid(tokenExpiry)) {
      dispatch(logout());
    }
    let data = {
      groups: [groupId],
      op: 'code_regenerate'
    };
    let groupsUrl = API_ROOT + 'groups';
    let token = creds.token;
    let options = {
      method: 'POST',
      headers: {
        Xauthtoken: token,
        'Content-type': 'application/json'
      },
      body: JSON.stringify(data)
    };
    let message = 'We could refresh groups access code at this moment.';
    fetch(groupsUrl, options)
      .then(response => {
        if (response.status >= 400) {
          if (response.status === 403) {
            dispatch(logout(false));
          }
          dispatch(toggleNotification(message));
          return null;
        }
        return response.json();
      })
      .then(json => {
        dispatch({
          type: REFRESH_ACCESS_CODE_RECIEVED,
          payload: {
            groupId,
            json
          }
        });
      });
  };
};

export function deleteGroupAction(id, start, limit) {
  let data = {
    groups: [id],
    op: 'delete'
  };
  return (dispatch, getState) => {
    dispatch({
      type: DELETE_GROUP_REQUESTED
    });
    let usersUpdateUrl = API_ROOT + 'groups';
    const token = getState().user.token;
    let options = {
      method: 'POST',
      headers: {
        Xauthtoken: token,
        'Content-type': 'application/json'
      },
      body: JSON.stringify(data)
    };
    fetch(usersUpdateUrl, options)
      .then(response => {
        if (response.status >= 400) {
          if (response.status === 403) {
            dispatch(logout(false));
          }
          dispatch(toggleNotification(ACCESS_DENIED_MESSAGE));
          return null;
        }
        return response.json();
      })
      .then(json => {
        if (json !== null) {
          dispatch(getGroupsData(true, start, limit));
          dispatch(toggleSnackbar());
          setTimeout(() => dispatch(toggleSnackbar()), 3000);
          dispatch({
            type: DELETE_GROUP_RECIEVED,
            payload: {
              id
            }
          });
        }
      });
  };
}

export function deleteGroup(state, groupId) {
  const groups = state.data.filter(group => {
    return group.id !== groupId;
  });
  return {
    ...state,
    data: groups
  };
}

export function toggleSnackbar() {
  return dispatch => {
    dispatch({
      type: TOGGLE_NOTIFICATION_FOR_GROUPS
    });
  };
}
export function performSort(state, order, key) {
  const sortState = {
    groupsColumnInSort: order === 'none' ? null : key,
    sortBy: order
  };
  return {
    ...state,
    ...sortState
  };
}

export function groupsSort(key, order) {
  return dispatch => {
    dispatch({
      type: GROUPS_SORT,
      payload: {
        key,
        order
      }
    });
  };
}
export const getGroupsData = (
  isInitialLoad,
  start,
  limit,
  searchData = null
) => {
  return (dispatch, getState) => {
    dispatch({
      type: GROUPS_DATA_REQUESTED
    });
    const creds = getState().user;
    const tokenExpiry = creds.expiresAt;
    if (!isTokenValid(tokenExpiry)) {
      dispatch(logout());
    }
    let sortField = getState().groups.groupsColumnInSort;
    let orderby = getState().groups.sortBy;
    const sortFieldParam = sortField === null ? '' : `&sort_field=${sortField}`;
    const sortOrder =
      orderby === 'none' ? '&sort_order=asc' : `&sort_order=${orderby}`;
    const limitParam = `&limit=${limit}`;
    const startParam = `start=${start}`;
    const searchParam =
      searchData === null || searchData.trim() === ''
        ? ''
        : `&searchstring=${searchData}`;

    let groupsUrl =
      `${API_ROOT}groups?` +
      `${startParam}${limitParam}` +
      `${sortOrder}${sortFieldParam}` +
      `${searchParam}`;

    let token = creds.token;
    let options = {
      headers: {
        Xauthtoken: token
      }
    };
    let serverErrMsg = 'We could not find groups at this moment.';
    let message = 'Sorry! Group does not exist';
    fetch(groupsUrl, options)
      .then(response => {
        if (response.status >= 400) {
          if (response.status === 403) {
            dispatch(logout(false));
          } else {
            if (response.status === 400) {
              dispatch(toggleNotification(message));
            } else {
              dispatch(toggleNotification(serverErrMsg));
            }
          }
          return null;
        }
        return response.json();
      })
      .then(json => {
        let totalNum = 0;
        let groups = {};

        if (json !== null && totalNum !== undefined) {
          totalNum = json.total_num;
          groups = json.groups;
          dispatch({
            type: GROUPS_DATA_RECIEVED,
            payload: {
              groups: groups,
              totalRecords: totalNum
            }
          });
          if (totalNum === 0) {
            dispatch(receivedPaginationData([], 0));
            return;
          }
          if (isInitialLoad) {
            const isSearch = searchData !== null;
            dispatch(receivedPaginationData(groups, totalNum, isSearch));
          }
          if (start > totalNum) {
            dispatch(getGroupsData(true, start - limit, limit));
          }
        }
      });
  };
};

export function updateGroupData(data, index) {
  let tempApps = {
    'Parking Genius' : 1,
    'Insights' : 2, 
    'HPE - Alpharetta' : 3,
    'Cepi - demo': 4,
    'DIA' : 5, 
    'Norfolk' : 6,
    'Avalon' : 7, 
    'SanMateo' : 8, 
    'Air Quality' : 9,
    'User Management' : 10,
    'User Registration' : 11,
    'AddAccess' : 12,
    'Flood Monitoring' : 13,
    'Farms - demo' : 14,
    'Parking Enforce' : 15,
    'Parking Verify' : 16,
    'OPS' : 17
  };
  let urlbody = JSON.parse(JSON.stringify(data));
  let appsId = [];
  data.apps.forEach(app => {
    appsId.push(tempApps[app]);
  });
  urlbody.apps = appsId;
  return (dispatch, getState) => {
    dispatch({
      type: UPDATE_GROUPS_DATA_REQUESTED
    });
    let groupUpdateUrl = API_ROOT + 'groups';
    const token = getState().user.token;
    let options = {
      method: 'POST',
      headers: {
        Xauthtoken: token,
        'Content-type': 'application/json'
      },
      body: JSON.stringify(urlbody)
    };
    fetch(groupUpdateUrl, options)
      .then(response => {
        if (response.status >= 400) {
          if (response.status === 403) {
            dispatch(logout(false));
          }
          dispatch(toggleNotification(ACCESS_DENIED_MESSAGE));
          return null;
        }
        return response.json();
      })
      .then(json => {
        if (json !== null) {
          dispatch({
            type: UPDATE_GROUPS_DATA_RECIEVED,
            payload: {
              data,
              index
            }
          });
        }
      });
  };
}

export function getDistData(data) {
  return (dispatch, getState) => {
    dispatch({
      type: DISTRICT_DATA_REQUESTED
    });
    let tokenExpiry = getState().user.expiresAt;
    if (!isTokenValid(tokenExpiry)) {
      dispatch(logout());
    }
    let distsUrl = API_ROOT + 'groups';
    let token = getState().user.token;
    let options = {
      method: 'POST',
      headers: {
        Xauthtoken: token,
        'Content-type': 'application/json'
      },
      body: JSON.stringify(data)
    };
    let message = 'We could not find district detail at this moment.';
    fetch(distsUrl, options)
      .then(response => {
        if (response.status >= 400) {
          if (response.status === 403) {
            dispatch(logout(false));
          }
          dispatch(toggleNotification(message));
          return null;
        }
        return response.json();
      })
      .then(json => {
        if (json !== null) {
          let data = transformDistrictData(json.groups);
          dispatch({
            type: DISTRICT_DATA_RECIEVED,
            payload: {
              data: data
            }
          });
        }
      });
  };
}

export function createGroup(data) {
  return (dispatch, getState) => {
    dispatch({
      type: CREATE_GROUP_REQUESTED
    });
    let usersUpdateUrl = API_ROOT + 'addgroup';
    const token = getState().user.token;
    let options = {
      method: 'POST',
      headers: {
        Xauthtoken: token,
        'Content-type': 'application/json'
      },
      body: JSON.stringify(data)
    };
    let message = 'We could not create new group at this moment.';
    fetch(usersUpdateUrl, options)
      .then(response => {
        if (response.status >= 400) {
          if (response.status === 403) {
            dispatch(logout(false));
          }
          dispatch(toggleNotification(message));
          return null;
        }
        return response.json();
      })
      .then(json => {
        if (json !== null) {
          dispatch({
            type: NEW_GROUP_RECIEVED
          });
        }
      });
  };
}

function transformDistrictData(groups) {
  let distObject = groups.reduce((result, obj) => {
    result[obj.id] = obj.district_ids;
    return result;
  }, {});
  return distObject;
}
function transformGroupData(groups) {
  let distObject = groups.reduce((result, obj) => {
    result[obj.id] = obj.name;
    return result;
  }, {});
  return distObject;
}

export function getAllGroupsData(uniqueGroups) {
  let grpNameReq = {
    groups: uniqueGroups,
    op: 'fetch'
  };
  return (dispatch, getState) => {
    dispatch({
      type: All_GROUPS_DATA_REQUESTED
    });
    let tokenExpiry = getState().user.expiresAt;
    if (!isTokenValid(tokenExpiry)) {
      dispatch(logout());
    }
    let allGroupsUrl = API_ROOT + 'groups';
    let token = getState().user.token;
    let options = {
      method: 'POST',
      headers: {
        Xauthtoken: token,
        'Content-type': 'application/json'
      },
      body: JSON.stringify(grpNameReq)
    };
    let message = 'We could not find group names at this moment.';
    fetch(allGroupsUrl, options)
      .then(response => {
        if (response.status >= 400) {
          if (response.status === 403) {
            dispatch(logout(false));
          }
          dispatch(toggleNotification(message));
          return null;
        }
        return response.json();
      })
      .then(json => {
        if (json !== null) {
          let data = transformGroupData(json.groups);
          dispatch({
            type: All_GROUPS_DATA_RECIEVED,
            payload: {
              data: data
            }
          });
        }
      });
  };
}
