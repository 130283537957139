export function getRowHeightFor(type) {
  if (type === 'thick') {
    return 80;
  } else if (type === 'medium') {
    return 64;
  } else if (type === 'thin') {
    return 48;
  } else {
    return 64;
  }
};

export function getPageDimensions(rowHeight) {
  const pageHeight = document.documentElement.clientHeight;
  const header = 60;
  const tabs = 56;
  const tableHeaders = 88;
  const footer = 64;
  const remainingHeight =
    pageHeight - (header + tableHeaders + tabs + footer);
  const noOfRows = Math.ceil(remainingHeight / rowHeight);
  return {
    height: remainingHeight,
    noOfRows
  };
};
