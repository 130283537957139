import React, { Component } from 'react';
import DropDown from './dropDown';
import Option from '../option/option';
import { css } from 'emotion';
class Districts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tempDistrictsIds: [], // this variable is going to hold temporary data so
      //that upon clicking on cancel button we will not have any impact on old data
      showDist: false,
      displayList: false
    };
    this.onOptionSelected = this.onOptionSelected.bind(this);
  }

  distIds = []; //this variable will hold group ids of groups.

  componentDidMount() {
    if (this.distIds !== undefined) {
      this.setState({
        tempDistrictsIds: this.distIds.slice(0)
      });
    }
  }

  onOptionSelected(id, checked) {
    let clickedDistId = this.props.districtsData.data[id];
    let distIdsCopy;
    if (checked) {
      distIdsCopy = this.state.tempDistrictsIds.slice(0);
      distIdsCopy.push(clickedDistId.id);
    } else {
      distIdsCopy = this.state.tempDistrictsIds.filter(
        value => value !== clickedDistId.id
      );
    }
    this.setState({
      tempDistrictsIds: distIdsCopy
    }, () => {
      this.props.getSelectedDistrictIds(this.state.tempDistrictsIds)
    });
  }

  renderDistricts = () => {
    var len = this.props.districtsData.data[0].id.toString().length;
    this.props.districtsData.data.map(
      v => (len = Math.max(len, v['id'].toString().length))
    );
    return (
      <div className={distData + ' thin-vert-scrollbar'}>
        {this.props.districtsData.data.map((dist, index) => {
          return (
            <Option
              Id={dist['id']}
              Name={dist['name']}
              isSelected={this.state.tempDistrictsIds.includes(dist['id'])}
              key={index}
              onSelect={this.onOptionSelected}
              keyId={index}
              idWidth={len}
            />
          );
        })}
      </div>
    );
  };

  renderSelectedDistrictIds = () => {
    if (this.state.tempDistrictsIds.length === 0) {
      return <div className={textStyle}> Add districts </div>;
    }
    return this.state.tempDistrictsIds.map((distIds, index) => {
      return (
        <div className={distCell} key={index}>
          {distIds}
        </div>
      );
    });
  };
  toggleArrows = () => {
    this.setState({
      displayList: !this.state.displayList
    });
  };

  hadleAddDistrict = () => {
    this.toggleArrows();
    this.setState({
      showDist: !this.state.showDist
    });
  };

  displayErrorMSg = () => {
    const errorMsg = 'District ID cannot be left blank';
    if (this.props.showMsg && this.state.tempDistrictsIds.length === 0) {
      return (
        <div className={msgContainer}>
          <span className={errorMsgStyle}> {errorMsg} </span>{' '}
        </div>
      );
    } else return null;
  };

  render() {
    return (
      <div>
        <DropDown
          handleArrow={this.hadleAddDistrict}
          renderSelectedItems={this.renderSelectedDistrictIds}
          displayList={this.state.displayList}
          requiredStyle={this.state.requiredStyle}
        />
        {this.displayErrorMSg()}
        {this.state.showDist ? this.renderDistricts(this.props) : null}
      </div>
    );
  }
}

const msgContainer = css({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  marginLeft: '16px'
});

const errorMsgStyle = css({
  width: '165px',
  height: '18px',
  fontFamily: 'Open Sans',
  fontSize: '12px',
  fontWeight: 'normal',
  fontStyle: 'normal',
  fontStretch: 'normal',
  lineHeight: 1.5,
  letterSpacing: 'normal',
  color: '#ff5630'
});

const distData = css({
  height: '336px',
  overflowY: 'scroll',
  position: 'fixed',
  top: '200px',
  zIndex: 1,
  background: '#ffffff',
  padding: '8px',
  margin: '0 16px 16px 16px',
  borderRadius: '2px',
  boxShadow: '0 3px 10px 0 rgba(0, 0, 0, 0.09)',
  border: 'solid 1px #ebecf0'
});

const textStyle = css({
  paddingLeft: '16px',
  fontFamily: 'Open Sans',
  fontSize: '14px',
  fontWeight: 'normal',
  fontStyle: 'normal',
  fontStretch: 'normal',
  lineHeight: 1.5,
  letterSpacing: 'normal',
  color: '#c1c7d0'
});

const distCell = css({
  borderRadius: '2px',
  border: 'solid 1px #ebecf0',
  backgroundColor: '#ffffff',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  fontFamily: 'Open Sans',
  fontSize: '14px',
  fontStyle: 'normal',
  fontStretch: 'normal',
  lineHeight: '1.5',
  letterSpacing: 'normal',
  color: '#6c798f',
  textAlign: 'left',
  minWidth: '84px',
  minHeight: '32px',
  alignItems: 'center',
  margin: '5px'
});
export default Districts;
