import React, { Component } from 'react';
import Login from './login';
import LoginError from './loginerror';
import AppHeader from '../app-header/app-header';
import Modal from '../modal/modal';
import { css } from 'emotion';

import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
  loginWithCreds,
  USER_LOG_IN_REQESTED,
  USER_LOG_IN_ERROR,
  USER_LOG_IN_DENIED
} from '../../modules/user';

class LoginContainer extends Component {
  onLoginSubmit = (user, pass) => {
    this.props.loginWithCreds(user, pass);
  };

  componentWillReceiveProps(props) {
    let isLoggedIn = props.user.isLoggedIn;
    if (isLoggedIn) {
      this.props.goToFieldPage();
    }
  }

  render() {
    let homeBtn = {
      name: 'Home',
      func: this.props.goToFieldPage
    };
    const userStatus = this.props.user.status;
    const loginStatus = userStatus === USER_LOG_IN_REQESTED;
    const loginError =
      userStatus === USER_LOG_IN_ERROR || userStatus === USER_LOG_IN_DENIED;
    const errorMessage = this.props.user.loginErrorMessage || '';

    return (
      <div className={loginContainer}>
        <AppHeader button={homeBtn} />
        <Modal title="Welcome" subtitle="Login to your Fybr Account">
          <LoginError show={loginError} content={errorMessage} />
          <Login
            onSubmit={this.onLoginSubmit}
            isLoggedIn={this.props.user.isLoggedIn}
            loading={loginStatus}
          />
        </Modal>
        <div className={footerContainer}>
          <div className={footerClass}>
            Copyright &copy; 2017 Fybr. All rights reserved.
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      loginWithCreds,
      goToFieldPage: () => push('/')
    },
    dispatch
  );

const loginContainer = css({
  backgroundColor: '#202e38',
  minHeight: '100vh',
  position: 'relative'
});

const footerClass = css({
  position: 'absolute',
  left: 0,
  bottom: '24px',
  fontFamily: 'Open Sans',
  fontSize: '14px',
  color: '#BDBDBD',
  letterSpacing: '0.5px',
  lineHeight: '21px',
  width: '100%',
  textAlign: 'center'
});
const footerContainer = css({
  position: 'relative',
  minHeight: '75px',
  marginTop: '25px'
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);
