import React, { Component } from 'react';
import upArrow from '../../assets/sort-asc-arrow.svg';
import downArrow from '../../assets/sort-desc-arrow.svg';
import { css } from 'emotion';
import RadioBox from '../radiobox/radiobox';
import { handleRadioButton } from '../../modules/users';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { USERS, GROUPS } from '../../modules/users';
class UsersEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isExpanded: false,
      users: ['USERS', 'GROUPS']
    };
    this.node = null;
  }

  toggle = () => {
    this.setState(prevState => ({
      isExpanded: !prevState.isExpanded
    }));
  };
  handleClickOutside = () => {
    this.setState({
      isExpanded: false
    });
  };

  handleClick = e => {
    if (this.node !== null && this.node.contains(e.target)) {
      return;
    }
    this.handleClickOutside();
  };

  componentWillMount() {
    document.addEventListener('mousedown', this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
  }
  post = e => {
    this.props.resetSearchKey();    
    this.props.handleRadioButton(e.target.value);
    this.setState({
      isExpanded: false
    });
  };
  renderItems = users => {
    return (
      <div className={container}>
        {users.map(usr => {
          return (
            <div className={radioBoxContainer} key={usr}>
              <RadioBox
                id={usr}
                label={usr}
                onSelect={e => this.post(e)}
                selected={this.props.selected}
              />
            </div>
          );
        })}
      </div>
    );
  };
  render() {
    const isExpanded = this.state.isExpanded;
    const arrowIcon = isExpanded ? upArrow : downArrow;

    return (
      <div ref={node => (this.node = node)}>
        <div className={users} onClick={this.toggle}>
          <div className={span}>
            <span>{this.props.users.isUserSelected ? USERS : GROUPS}</span>
            <img src={arrowIcon} alt="" style={{ paddingLeft: '10px' }} />
          </div>
        </div>
        {this.state.isExpanded ? this.renderItems(this.state.users) : null}
      </div>
    );
  }
}
const container = css({
  zIndex: 1,
  width: '200px',
  minHeight: '128px',
  left: '5px',
  position: 'absolute',
  backgroundColor: '#ffffff',
  borderRadius: '4px',
  boxShadow: '0 3px 10px 0 rgba(0, 0, 0, 0.09)'
});

const radioBoxContainer = css({
  display: 'flex',
  flexDirection: 'row',
  height: '64px',
  alignItems: 'center',
  border: 'solid 1px #ebecf0'
});

const users = css({
  width: '60%',
  height: '48px',
  textAlign: 'left',
  display: 'table'
});

const span = css({
  display: 'table-cell',
  verticalAlign: 'middle'
});

const mapStateToProps = state => ({
  users: state.users,
  pagination: state.pagination
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      handleRadioButton
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(UsersEditor);
