import React, { Component } from 'react';
import { css } from 'emotion';
import leftArrow from '../../assets/paginate-left-arrow.svg';
import leftArrowDisabled from '../../assets/left-arrow-disabled.svg';

import rightArrow from '../../assets/paginate-right-arrow.svg';
import rightArrowDisabled from '../../assets/right-arrow-disabled.svg';
import { debounce } from 'lodash';

export default class Pagination extends Component {
  constructor(props) {
    super(props);
    this.onLeftClicked = this.onLeftClicked.bind(this);
    this.onRightClicked = this.onRightClicked.bind(this);
    this.onLimitSelected = this.onLimitSelected.bind(this);
    this.handlePrevButton = this.handlePrevButton.bind(this);
    this.handleNextButton = this.handleNextButton.bind(this);
    this.onHover = this.onHover.bind(this);
    this.state = {
      showOptions: false
    };
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.node = null;
  }

  onHover() {
    this.setState({
      showOptions: true
    });
  }

  handleClickOutside() {
    this.setState({
      showOptions: false
    });
  }

  handleClick = e => {
    if (this.node !== null && this.node.contains(e.target)) {
      return;
    }
    if (e.target.id === 'limitElement') {
      return;
    }
    this.handleClickOutside();
  };

  onLeftClicked = debounce(() => {
    this.props.isSearchActive === false
      ? this.props.getData(false, this.props.start, this.props.limit)
      : this.props.getData(
          false,
          this.props.start,
          this.props.limit,
          this.props.searchKey
        );
  }, 1000);

  onRightClicked = debounce(() => {
    this.props.isSearchActive === false
      ? this.props.getData(false, this.props.start, this.props.limit)
      : this.props.getData(
          false,
          this.props.start,
          this.props.limit,
          this.props.searchKey
        );
  }, 1000);

  handlePrevButton() {
    if (this.props.start - this.props.limit > 0) {
      this.onLeftClicked();
    }
    this.props.left(this.props.totalRecords);
  }

  handleNextButton() {
    if (this.props.start + this.props.limit <= this.props.totalRecords) {
      this.onRightClicked();
    }
    this.props.right(this.props.totalRecords);
  }

  onLimitSelected(limit) {
    this.props.isSearchActive === false
      ? this.props.getData(false, 1, limit)
      : this.props.getData(false, 1, this.props.limit, this.props.searchKey);
    this.props.reset();
    this.props.changeDataLimit(limit, this.props.totalRecords, false, false);
    this.setState({
      showOptions: false
    });
  }

  componentWillMount() {
    document.addEventListener('mousedown', this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
  }

  renderLimitOptions() {
    const showOptions = this.state.showOptions;
    if (!showOptions) {
      return null;
    }
    const elementStyle = css({
      display: 'flex',
      height: '24px',
      justifyContent: 'center',
      padding: '5px 10px 5px 10px',
      '&:hover': {
        backgroundColor: '#deebff'
      }
    });
    const maxElements = this.props.totalRecords;
    const defaultLimit = this.props.defaultLimit;
    const limitMultiples = [1, 2, 5].map(multiple => {
      return defaultLimit * multiple;
    });
    const limits = limitMultiples.filter(limit => {
      return (
        (limit / defaultLimit >= 1 || defaultLimit <= limit) &&
        limit <= maxElements
      );
    });
    const wrapper = css({
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#ffffff',
      position: 'absolute',
      top: `${limits.length * -25 - 10}px`,
      borderRadius: '4px',
      boxShadow: '0 3px 10px 0 rgba(0, 0, 0, 0.09)'
    });

    const limitElements = limits.map(limit => {
      return (
        <div
          id="limitElement"
          className={elementStyle}
          key={`limit-${limit}`}
          onClick={() => this.onLimitSelected(limit)}
        >
          {limit}
        </div>
      );
    });
    const wrappedElements = <div className={wrapper}>{limitElements}</div>;
    return wrappedElements;
  }

  render() {
    const start = this.props.start;
    const end = this.props.end;
    const num = this.props.totalRecords;
    if(num === 0) {
      return null;
    }
    const isLeftDisabled = start === 1;
    const isRightDisabled = end === num;
    const leftArrowStyle = css({
      display: 'flex',
      justifyContent: 'center',
      width: '38px',
      height: '38px',
      borderRadius: '2px',
      border: '1px solid #DFE1E5',
      backgroundColor: isLeftDisabled ? '#f4f5f7' : '#FFFFFF',
      userSelect: 'none'
    });

    const rightArrowStyle = css({
      display: 'flex',
      justifyContent: 'center',
      width: '38px',
      height: '38px',
      borderRadius: '2px',
      border: '1px solid #DFE1E5',
      backgroundColor: isRightDisabled ? '#f4f5f7' : '#FFFFFF',
      userSelect: 'none'
    });
    const leftArrowSrc = isLeftDisabled ? leftArrowDisabled : leftArrow;
    const rightArrowSrc = isRightDisabled ? rightArrowDisabled : rightArrow;
    return (
      <div className={container}>
        <div>{this.renderLimitOptions()}</div>
        <div className={pageText}>
          <div className={limitStyle} onMouseEnter={this.onHover}>
            {start} - {end}
          </div>
          <div>of {num}</div>
        </div>
        <div className={arrows}>
          <div className={leftArrowStyle} onClick={this.handlePrevButton}>
            <img src={leftArrowSrc} alt="<" />
          </div>
          <div className={rightArrowStyle} onClick={this.handleNextButton}>
            <img src={rightArrowSrc} alt=">" />
          </div>
        </div>
      </div>
    );
  }
}

const container = css({
  display: 'flex',
  marginLeft: 'auto',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '10px'
});

const pageText = css({
  display: 'flex',
  paddingRight: '10px',
  alignItems: 'center',
  fontFamily: 'Open Sans',
  fontSize: '14px',
  fontStyle: 'normal',
  fontStretch: 'normal',
  lineHeight: '1.5',
  letterSpacing: 'normal'
});

const limitStyle = css({
  borderRadius: '2px',
  backgroundColor: '#deebff',
  padding: '5px',
  marginRight: '5px',
  marginLeft: '5px'
});

const arrows = css({
  display: 'flex',
  flexDirection: 'row'
});
