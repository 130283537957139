export const TOGGLE_MODAL = "TOGGLE_MODAL";

const initialState = {
  showModal: false,
  data: " "
};
export default (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_MODAL:
      return {
        ...state,
        showModal: !state.showModal,
        data: action.payload.data
      };
    default:
      return state;
  }
};

export const toggleNotification = data => {
  return dispatch => {
    dispatch({
      type: TOGGLE_MODAL,
      payload: {
        data: data
      }
    });
  };
};
