import React, { Component } from 'react';
import DropDown from './dropDown';
import Option from '../option/option';
import { css } from 'emotion';
class ApisList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tempApis: [], // this variable is going to hold temporary data so
      //that upon clicking on cancel button we will not have any impact on old data

      showApis: false,
      displayList: false
    };
    this.onOptionSelected = this.onOptionSelected.bind(this);
  }

  apiList = []; //this variable will hold group ids of groups.

  componentDidMount() {
    if (this.apiList !== undefined) {
      this.setState({
        tempApis: this.apiList.slice(0)
      });
    }
  }
  
  onOptionSelected(id, checked) {
    let clickedApis = this.props.apisData.data[id];
    let apisCopy;
    if (checked) {
      apisCopy = this.state.tempApis.slice(0);
      apisCopy.push(clickedApis);
    } else {
      apisCopy = this.state.tempApis.filter(value => !(value.method.includes(clickedApis.method) 
        && value.op.includes(clickedApis.op)));
    }
    this.setState({
      tempApis: apisCopy
    }, () =>{
      this.props.getSelectedApis(this.state.tempApis)
    });
  }
  renderApis = () => {
    return (
      <div className={apiData + ' thin-vert-scrollbar'}>
        {this.props.apisData.data.map((apiObj, index) => {
          return (
            <Option
              Name={apiObj.method + ' (' + apiObj.op + ')'}
              isSelected={this.isApiSelected(apiObj)}
              key={index}
              onSelect={this.onOptionSelected}
              keyId={index}
            />
          );
        })}
      </div>
    );
  };
  isApiSelected = (apiObj ) => {
    if(this.state.tempApis.length){
      for(let i = 0 ; i < this.state.tempApis.length; i++){
        if (this.state.tempApis[i].method.includes(apiObj.method) && this.state.tempApis[i].op.includes(apiObj.op)){
          return true
        }
      }
    }
    return false
  }

  renderSelectedApis = () => {
    if (this.state.tempApis.length === 0) {
      return <div className={textStyle}>Add API's</div>;
    }
    return this.state.tempApis.map((apis, index) => {
      return (
        <div className={apiCell} key={index}>
          <div className={apiItem}> {apis.method} </div>
        </div>
      );
    });
  };
  toggleArrows = () => {
    this.setState({
        displayList: !this.state.displayList
    });
  };

  handleAddApps = () => {
    this.toggleArrows();
    this.setState({
      showApps: !this.state.showApps
    });
  };

  render() {
    return (
      <div>
        <DropDown
          handleArrow={this.handleAddApps}
          renderSelectedItems={this.renderSelectedApis}
          displayList={this.state.displayList}
        />
        {this.state.showApps ? this.renderApis(this.props) : null}
      </div>
    );
  }
}
const apiData = css({
  width: '320px',
  height: '336px',
  overflowY: 'scroll',
  position: 'fixed',
  top: '335px',
  zIndex: 1,
  background: '#ffffff',
  padding: '8px',
  margin: '0 16px 16px 16px',
  borderRadius: '2px',
  boxShadow: '0 3px 10px 0 rgba(0, 0, 0, 0.09)',
  border: 'solid 1px #ebecf0'
});

const textStyle = css({
  paddingLeft: '16px',
  fontFamily: 'Open Sans',
  fontSize: '14px',
  fontWeight: 'normal',
  fontStyle: 'normal',
  fontStretch: 'normal',
  lineHeight: 1.5,
  letterSpacing: 'normal',
  color: '#c1c7d0'
});

const apiCell = css({
  borderRadius: '2px',
  border: 'solid 1px #ebecf0',
  backgroundColor: '#ffffff',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  fontFamily: 'Open Sans',
  fontSize: '14px',
  fontStyle: 'normal',
  fontStretch: 'normal',
  lineHeight: '1.5',
  letterSpacing: 'normal',
  color: '#6c798f',
  textAlign: 'left',
  minWidth: '84px',
  minHeight: '32px',
  alignItems: 'center',
  margin: '5px'
});
const apiItem = css({
  padding: "5px",
  wordBreak: "break-word",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis"
})
export default ApisList;
