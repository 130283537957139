import 'isomorphic-fetch';

export async function login(url, username, password) {
  let response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      user_email: username,
      password: password,
      ui: 10
    })
  });
  let status = response.status;
  if (status === 403 || status === 201 || status === 200) {
    return response.json();
  } else {
    return null;
  }
}
