import React, { Component } from 'react';
import { css } from 'emotion';
import Option from '../option/option';
import InfiniteScroll from 'react-infinite-scroll-component';
import './styles.css';

export default class AddGroupsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tempGroupIds: [] // this variable is going to hold temporary data so
      //that upon clicking on cancel button we will not have any impact on old data
    };
    this.node = null;
    this.onOptionSelected = this.onOptionSelected.bind(this);
  }
  groupIds = []; //this variable will hold group ids of users.

  componentDidMount() {
    this.setState({ tempGroupIds: this.groupIds.slice(0) });
    if (this.props.groupsData.data === null) {
      this.props.fetchAllGroups();
    }
  }

  componentWillMount() {
    document.addEventListener('mousedown', this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
  }

  handleClickOutside = () => {
    this.props.toggeleAddGroupsModal();
  };

  handleClick = e => {
    if (this.node !== null && this.node.contains(e.target)) {
      return;
    }
    this.handleClickOutside();
  };
  onOptionSelected(id, checked) {
    let clickedGroupId = this.props.groupsData.data[id];
    let groupIdsCopy;
    if (checked) {
      groupIdsCopy = this.state.tempGroupIds.slice(0);
      groupIdsCopy.push(clickedGroupId.id);
    } else {
      groupIdsCopy = this.state.tempGroupIds.filter(
        value => value !== clickedGroupId.id
      );
    }
    this.setState({ tempGroupIds: groupIdsCopy });
  }
  renderSelectedGroupIds = () => {
    return this.state.tempGroupIds.map((groupId, index) => {
      return (
        <div className={groupCell} key={index}>
          {groupId}
        </div>
      );
    });
  };
  renderGroups = () => {
    let data = this.props.groupsData.data;
    let len = data[0].id.toString().length;
    data.map(v => (len = Math.max(len, v['id'].toString().length)));
    return [
      data.map((group, index) => {
        return (
          <Option
            Id={group['id']}
            Name={group['name']}
            isSelected={this.state.tempGroupIds.includes(group['id'])}
            key={index}
            onSelect={this.onOptionSelected}
            keyId={index}
            idWidth={len}
          />
        );
      })
    ];
  };
  handleAddGroups = () => {
    this.props.updateUserGroupsData(
      this.state.tempGroupIds,
      this.props.editRowIndex
    );
    this.props.toggeleAddGroupsModal();
  };

  handleCancleBtn = () => {
    this.props.toggeleAddGroupsModal();
  };

  render() {
    this.groupIds = this.props.usersData[this.props.editRowIndex].group_ids;
    if (this.props.groupsData.data === null) {
      return null;
    }
    return (
      <div className={modalClass}>
        <div className={modalMain} ref={node => (this.node = node)}>
          <div className={container}>
            <span className={title}>Add Groups</span>
          </div>

          <div className={addgroupsItemStyle  + ' thin-horiz-scrollbar'}>
            {this.renderSelectedGroupIds()}
          </div>
          <div className={container}>
            <span className={title}>All Groups</span>
          </div>
          <div id="scrollableDiv" className={grpData  + ' thin-vert-scrollbar'}>
            <InfiniteScroll
              scrollableTarget="scrollableDiv"
              dataLength={this.props.groupsData.data.length}
              next={() => this.props.fetchAllGroups(this.props.groupsData.next)}
              hasMore={this.props.groupsData.next !== null}
              loader={<div className={loadingstyle}>Loading...</div>}
            >
              {this.renderGroups()}
            </InfiniteScroll>
          </div>
          <div className={btn}>
            <div className={cancleBtn} onClick={() => this.handleCancleBtn()}>
              CANCEL
            </div>
            <div
              className={addGroupsBtn}
              onClick={() => this.handleAddGroups()}
            >
              ADD GROUPS
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const modalClass = css({
  position: 'absolute',
  zIndex: 1,
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',
  background: 'rgba(0, 0, 0, 0.6)'
});

const modalMain = css({
  position: 'absolute',
  background: 'white',
  width: '364px',
  minHeight: '500px',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%,-50%)',
  borderRadius: '4px',
  boxShadow: '0 10px 10px 0 rgba(0, 0, 0, 0.18)'
});

const container = css({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  height: '48px'
});
const title = css({
  paddingLeft: '16px',
  fontFamily: 'Open Sans',
  fontSize: '16px',
  fontWeight: 600,
  fontStyle: 'normal',
  fontStretch: 'normal',
  lineHeight: 1.5,
  letterSpacing: 'normal',
  color: '#112138'
});
const addgroupsItemStyle = css({
  margin: 'auto',
  display: 'flex',
  padding: '5px',
  alignItems: 'center',
  flexDirection: 'row',
  width: '336px',
  height: '48px',
  borderRadius: '2px',
  border: 'solid 1px #dfe1e5',
  backgroundColor: '#ffffff',
  overflowY: 'scroll'
});

const btn = css({
  display: 'flex',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  width: '364px',
  height: '56px',
  borderRadius: '2px',
  backgroundColor: '#fafbfc'
});

const cancleBtn = css({
  width: '50%',
  padding: '16px',
  border: 'solid 1px #ebecf0',
  fontFamily: 'Open Sans',
  fontSize: '14px',
  fontWeight: '700',
  fontStyle: 'normal',
  fontStretch: 'normal',
  lineHeight: 1.5,
  letterSpacing: '1px',
  textAlign: 'center',
  color: '#c1c7d0',
  cursor: 'pointer'
});

const addGroupsBtn = css({
  border: 'solid 1px #ebecf0',
  width: '50%',
  padding: '16px',
  fontFamily: 'Open Sans',
  fontSize: '14px',
  fontWeight: '700',
  fontStyle: 'normal',
  fontStretch: 'normal',
  lineHeight: 1.5,
  letterSpacing: '1px',
  textAlign: 'center',
  color: '#0151cb',
  cursor: 'pointer'
});

const grpData = css({
  width: '100%',
  height: '300px',
  display: 'flex',
  overflow: 'auto',
  flexDirection: 'column'
});

const groupCell = css({
  borderRadius: '2px',
  border: 'solid 1px #ebecf0',
  backgroundColor: '#ffffff',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  fontFamily: 'Open Sans',
  fontSize: '14px',
  fontStyle: 'normal',
  fontStretch: 'normal',
  lineHeight: '1.5',
  letterSpacing: 'normal',
  color: '#6c798f',
  textAlign: 'left',
  minWidth: '84px',
  minHeight: '32px',
  alignItems: 'center',
  marginRight: '8px'
});

const loadingstyle = css({
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '700',
  fontStretch: 'normal',
  lineHeight: '1.5',
  letterSpacing: 'normal',
  color: '#112138',
  paddingLeft: '16px'
});
