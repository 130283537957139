import React, { Component } from 'react';
import { css } from 'emotion';
import { getRowHeightFor, getPageDimensions } from '../resizer/utils';
import largeRowButton from '../../assets/large-row.png';
import medRowButton from '../../assets/med-row.png';
import lowRowButton from '../../assets/low-row.png';
export default class RowResizer extends Component {

  constructor(props) {
    super(props);
    this.onThickBarSelected = this.onThickBarSelected.bind(this);
    this.onMediumBarSelected = this.onMediumBarSelected.bind(this);
    this.onThinBarSelected = this.onThinBarSelected.bind(this);
    this.state = {
      selected: 'medium'
    }
  }

  getLimitFor(type) {
    const height = getRowHeightFor(type)
    const dimensions = getPageDimensions(height);
    const newLimit = dimensions.noOfRows;
    return newLimit;
  }

  onThickBarSelected() {
    const type = 'thick';
    this.setState({
      selected: type
    });
    this.props.onRowSizeChanged(type);
    const newLimit = this.getLimitFor(type);
    this.props.shouldRefreshData(true);
    this.props.changeDataLimit(newLimit, this.props.totalRecords, true);
  }

  onMediumBarSelected() {
    const type = 'medium';
    this.setState({
      selected: type
    });
    this.props.onRowSizeChanged(type);
    const newLimit = this.getLimitFor(type);
    this.props.shouldRefreshData(true);
    this.props.changeDataLimit(newLimit, this.props.totalRecords, true);
  }

  onThinBarSelected() {
    const type = 'thin';
    this.setState({
      selected: type
    });
    this.props.onRowSizeChanged(type);
    const newLimit = this.getLimitFor(type);
    this.props.shouldRefreshData(true);
    this.props.changeDataLimit(newLimit, this.props.totalRecords, true);
  }

  render() {
    const container = css({
      display: 'flex',
      flexDirection: 'row',
      padding: '2px',
      backgroundColor: '#DFE1E5',
      borderRadius: '3px',
      height: '38px',
      alignItems: 'center',
      alignSelf: 'center'
    });
    // THICK BAR
    const isThickBarSelected = this.state.selected === 'thick';
    const barThickContainerStyle = css({
      backgroundColor: `${isThickBarSelected ? '#F4F5F7' : 'white'}`,
      padding: '10px',
      borderRadius: '2px 0px 0px 2px',
      marginRight: '2px',
      display: 'flex',
    });
    // MEDIUM BAR
    const isMediumBarSelected = this.state.selected === 'medium';
    const barMediumContainerStyle = css({
      backgroundColor: `${isMediumBarSelected ? '#F4F5F7' : 'white'}`,
      padding: '10px',
      display: 'flex',
    });
    // THIN BAR
    const isThinBarSelected = this.state.selected === 'thin';
    const barThinContainerStyle = css({
      backgroundColor: `${isThinBarSelected ? '#F4F5F7' : 'white'}`,
      padding: '10px',
      borderRadius: '0px 2px 2px 0px',
      marginLeft: '2px',
      display: 'flex',
    });

    return (
      <div className={container}>
        <div className={barThickContainerStyle} onClick={this.onThickBarSelected}>
          <img src={largeRowButton} alt={'-'}/>
        </div>
        <div className={barMediumContainerStyle} onClick={this.onMediumBarSelected}>
          <img src={medRowButton} alt={'-'}/>
        </div>
        <div className={barThinContainerStyle} onClick={this.onThinBarSelected}>
          <img src={lowRowButton} alt={'-'}/>
        </div>
      </div>
    )
  }

}
