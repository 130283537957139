import { logout } from './user.js';
import persistor from '../index';
import { REHYDRATE } from 'redux-persist/constants';
import { toggleNotification } from './notification';
import { API_ROOT } from '../components/constants/api';
import { isTokenValid } from '../utils/user';
export const FETCH_GROUPS_REQUESTED = 'FETCH_GROUPS_REQUESTED';
export const SCROLL_GROUPS_DATA_FAILURE = "SCROLL_GROUPS_DATA_FAILURE";
export const SCROLL_GROUPS_DATA_RECIEVED = "SCROLL_GROUPS_DATA_RECIEVED";
const initialState = {
  data: null,
  next: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case REHYDRATE:
      persistor.purge();
      return state;

    case FETCH_GROUPS_REQUESTED:
      return {
        ...state,
        status: FETCH_GROUPS_REQUESTED
      };
    case SCROLL_GROUPS_DATA_FAILURE:
      return {
        ...state,
        status: SCROLL_GROUPS_DATA_FAILURE
      };
    case SCROLL_GROUPS_DATA_RECIEVED:
      let payload = action.payload;
      let newData = state.data !== null ? state.data.concat(payload.groups) : payload.groups;
      return {
        ...state,
        data: newData,
        next: payload.next,
        totalNum: payload.totalNum,
        status: SCROLL_GROUPS_DATA_RECIEVED,
      };
    default:
      return state;
  }
};
export function fetchAllGroupsRequest() {
    return { type: FETCH_GROUPS_REQUESTED };
  }  
function fetchAllGroupsSuccess(groups, next, totalNum) {
    return { type: SCROLL_GROUPS_DATA_RECIEVED, payload: { groups, next, totalNum } };
}
export function fetchAllGroups(next) {
    return (dispatch, getState) => {
      dispatch(fetchAllGroupsRequest());
      const creds = getState().user;
      const tokenExpiry = creds.expiresAt;
      if (!isTokenValid(tokenExpiry)) {
          dispatch(logout());
        }
        const token = creds.token;
        let message = 'We could not find groups detail at this moment.';
        fetch(API_ROOT + (next ? next : 'groups?start=1&limit=10'), {
            method: 'GET',
            headers: {
            Xauthtoken: token
            }
        })
        .then(response => {
            if (response.status >= 400) {
              if (response.status === 403) {
                dispatch(logout(false));
              }
              dispatch(toggleNotification(message));
              return null;
            }
            return response.json();
          })
        .then(json => {
          dispatch(fetchAllGroupsSuccess(json.groups, json.next ? json.next : null, json.total_num));
        })
    };
  }