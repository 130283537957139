import React, { Component } from 'react';
import whiteTickImage from '../../assets/white-blue-tick.svg';
import { css } from 'emotion';

export default class Option extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: this.props.isSelected
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      checked: nextProps.isSelected
    });
  }

  handleChange = event => {
    this.setState({ checked: event.target.checked });
    this.props.onSelect(event.target.id, event.target.checked);
  };

  render() {
    const container = css({
      paddingBottom: '10px',
      paddingTop: '10px',
      width: '100%'
    });

    const labelStyle = css({
      display: 'flex',
      alignItems: 'center',
      marginLeft: '50px'
    });

    const groupIdStyle = css({
      fontFamily: 'Open Sans',
      fontSize: '14px',
      fontWeight: '700',
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: 1.71,
      letterSpacing: 'normal',
      color: '#112138'
    });
    const groupNameStyle = css({
      marginLeft: '20px',
      fontFamily: 'Open Sans',
      fontSize: '14px',
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: 1.71,
      letterSpacing: 'normal',
      color: '#112138'
    });
    const checkbox = css({
      display: 'flex',
      width: '0px',
      height: '0px',
      position: 'relative',
      '& input[type=checkbox]': {
        visibility: 'hidden'
      },
      '& label': {
        display: 'inline-block',
        cursor: 'pointer',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '18px',
        height: '18px',
        boxSizing: 'border-box',
        marginLeft: '15px',
        marginTop: '3px',
        border: 'solid 1px #c1c7d0',
        borderRadius: '2px'
      },
      '& input[type=checkbox]:checked + label': {
        content: `url(${whiteTickImage})`,
        transition: 'all .5s ease',
        border: 0,
        backgroundColor: '#0151cb'
      }
    });
    
    let idwidth = this.props.idWidth * 8;
    return (
      <div className={container}>
        <div className={checkbox}>
          <input
            id={this.props.keyId}
            type="checkbox"
            onChange={this.handleChange}
            checked={this.state.checked}
            value={' '}
          />
          <label htmlFor={this.props.keyId} />
        </div>
        <div className={labelStyle}>
          <div className={groupIdStyle} style={{ width: `${idwidth}px` }}>
            {this.props.Id}
          </div>
          <div className={groupNameStyle}>{this.props.Name}</div>
        </div>
      </div>
    );
  }
}