import { login } from '../components/api/login';
import { API_ROOT } from '../components/constants/api';
import { REHYDRATE } from 'redux-persist/constants';
import { isTokenInLocalStorageValid } from '../utils/user';
import persistor from '../index';

export const USER_LOGGED_IN = 'USER_LOGGED_IN';
export const USER_LOG_IN_REQESTED = 'USER_LOG_IN_REQESTED';
export const USER_LOG_IN_ERROR = 'USER_LOG_IN_ERROR';
export const USER_LOGGED_OUT = 'USER_LOGGED_OUT';
export const CHECK_USER_TIMEOUT = 'CHECK_USER_TIMEOUT';
export const INTERVAL_UPDATE = 'INTERVAL_UPDATE';
export const USER_LOG_IN_DENIED = 'USER_LOG_IN_DENIED';
const LOGIN_FAILURE_MSG =
  'Sorry! Your attempt to login has failed. Please try again.';
const LOGIN_DENIED_MSG = 'You are not authorized to access this application';
export const TIMER_INTERVAL = 600000; //10mins interval, adjust as needed
const initialState = {
  isLoggedIn: false,
  timerID: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case REHYDRATE:
      let isValid = isTokenInLocalStorageValid();
      if (isValid) {
        let storedState = action.payload.user;
        let newState = Object.assign({}, storedState, { status: null });
        return newState;
      }
      persistor.purge();
      return state;
    case USER_LOGGED_IN:
      let payload = action.payload;
      return {
        ...state,
        isLoggedIn: true,
        email: payload.email,
        expiresAt: payload.expiresAt,
        name: payload.name,
        token: payload.token,
        timerID: payload.timerID,
        status: USER_LOGGED_IN
      };
    case USER_LOGGED_OUT:
      clearInterval(state.timerID);
      return {
        ...state,
        isLoggedIn: false,
        expiresAt: null,
        email: null,
        name: null,
        token: null,
        timerID: null,
        status: USER_LOGGED_OUT
      };
    case USER_LOG_IN_REQESTED:
      return {
        ...state,
        isLoggedIn: false,
        status: USER_LOG_IN_REQESTED
      };
    case USER_LOG_IN_ERROR:
      return {
        ...state,
        isLoggedIn: false,
        status: USER_LOG_IN_ERROR,
        loginErrorMessage: LOGIN_FAILURE_MSG
      };
    case USER_LOG_IN_DENIED:
      return {
        ...state,
        isLoggedIn: false,
        status: USER_LOG_IN_DENIED,
        loginErrorMessage: LOGIN_DENIED_MSG
      };
    case CHECK_USER_TIMEOUT:
      return checkUserTimeout(state);
    case INTERVAL_UPDATE:
      return {
        ...state,
        timerID: action.payload.timerID
      };
    default:
      return state;
  }
};

export const loginWithCreds = (userName, password) => {
  return dispatch => {
    dispatch({
      type: USER_LOG_IN_REQESTED
    });
    login(API_ROOT + 'login', userName, password).then(json => {
      const info = json !== null ? json.info : null;
      if (json === null) {
        dispatch({
          type: USER_LOG_IN_ERROR
        });
      } else if (info) {
        const errorCode = info.errorCode;
        if (errorCode === 1) {
          dispatch({
            type: USER_LOG_IN_DENIED
          });
        } else {
          dispatch({
            type: USER_LOG_IN_ERROR
          });
        }
      } else {
        dispatch({
          type: USER_LOGGED_IN,
          payload: {
            email: json.email,
            expiresAt: json.expires_at,
            name: json.name,
            token: json.token
          }
        });
      }
    });
  };
};

export function logout(informServer = true) {
  return (dispatch, getState) => {
    if (informServer) {
      const token = getState().user.token;
      fetch(API_ROOT + 'logout.json', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Xauthtoken: token
        }
      }).then(response => {
        if (response.status !== 201) {
          console.log('logout error:', response);
          return;
        }
      });
    }

    dispatch({
      type: USER_LOGGED_OUT
    });
    persistor.purge();
  };
}

function checkUserTimeout(state) {
  if (!isTokenInLocalStorageValid()) {
    //logout
    persistor.purge();
    window.location.href = '/';
    return {
      ...state,
      isLoggedIn: false,
      expiresAt: null,
      email: null,
      name: null,
      token: null,
      status: USER_LOGGED_OUT
    };
  }
  return { ...state };
}
export function checkUserTimeoutAndLogout() {
  return dispatch => {
    dispatch({
      type: CHECK_USER_TIMEOUT
    });
  };
}

export function checkLoginTimeout(userState) {
  return dispatch => {
    if (userState.timerID) {
      clearTimeout(userState.timerID);
    }
    let timer = setInterval(() => {
      dispatch({
        type: CHECK_USER_TIMEOUT
      });
    }, TIMER_INTERVAL);
    dispatch({
      type: INTERVAL_UPDATE,
      payload: {
        timerID: timer
      }
    });
  };
}
export function clearLoginTimeout(userState) {
  return dispatch => {
    if (userState.timerID) {
      clearTimeout(userState.timerID);
    }
    dispatch({
      type: INTERVAL_UPDATE,
      payload: {
        timerID: null
      }
    });
  };
}
